// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";

import simplicityImg from 'assets/images/icon-simplicity.png';
import programImg from 'assets/images/icon-programmability.png';
import highPerfImg from 'assets/images/icon-high-performance.png';
import secureImg from 'assets/images/icon-secure.png';

import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

import pxToRem from "assets/theme/functions/pxToRem";

function FeatureTemplate({ className, title, featureDescs, image }) {

  const featurePoint = (key, description) => {
    return (
      <ArgonBox key={key} px={2} pb={2} display="flex" alignItems="center">
        <ArgonBox display="flex" bgColor="yellow" width="8px" height="8px" mr={2} />
        <ArgonTypography display="flex" variant="d8_5" textAlign="left">
          {description}
        </ArgonTypography>
      </ArgonBox>
    )
  }

  return (
    <ArgonBox className={className} my={2} mx={{xs:0, lg:2}}>
      <ArgonBox p={2} textAlign="left" lineHeight={1}>
        <ArgonBox component="img" src={image} height="72px" />
      </ArgonBox>
      <ArgonBox px={2} pb={2} textAlign="left">
        <ArgonTypography variant="h4" textTransform="capitalize" color="white">
          {title}
        </ArgonTypography>
      </ArgonBox>
      {
        (featureDescs || []).map((desc, idx) => {
          return featurePoint(`ft_${idx}`, desc)
        })
      }
    </ArgonBox>
  );
}

const FeatureCard = styled(FeatureTemplate)({
  backgroundColor: "black",
  border: `2px solid #3B3B3B`,
  borderRadius: '4px',
})

function ContractSection({ className }) {
  const location = useLocation();
  const id = "smart-contract-section";
  const pixResolution = 50;
  const ref = useRef(null);
  const [mt, setMT] = useState(0);
  const [mb, setMB] = useState(0);

  const scrollIntoView = () => {
    if (window.location.hash === `#${id}`) {
      document.querySelector(`#${id}`).scrollIntoView();
    }
  }

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        const height = ref.current.offsetHeight;
        const offset = height * 5 / 6;
        setMT(Math.floor(offset / pixResolution));
        setMB(Math.floor((height - offset) / pixResolution));
        return;
      }
    }

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    }
  }, []);

  useEffect(() => {
    setTimeout(scrollIntoView, 50);
  }, [location]);

  return (
    <>
      {/* upper part in the black background color */}
      <Container id={id} sx={{ paddingTop: pxToRem(mt * pixResolution), position: 'relative' }}>
        <ArgonBox ref={ref} display="flex" className={className} position="absolute" justifyContent="space-between" alignItems="center" flexDirection="column" top={0} left={0}>
          <ArgonBox display="flex" alignItems="center" pt={3} px={2}>
            <ArgonTypography variant="h2" color="white" textTransform="uppercase">
              sCrypt Smart Contracts
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" alignItems="center" p={2}>
            <ArgonTypography variant="subtitle1" color="white">
              sCrypt is a Typescript framework to develop smart contracts on Bitcoin compatible blockchains.
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" py={2} width="calc(100% - 3rem)">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Simplicity"
                  featureDescs={
                    [
                      "Write Layer-1 smart contracts directly in TypeScript",
                      "Excellent tooling support like NPM and Visual Studio Code",
                    ]
                  }
                  image={simplicityImg}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Scalability"
                  featureDescs={
                    [
                      "The most unboundedly scalable blockchain, 1,000,000 TPS",
                      "Transaction fee as low as $0.00001 ",
                    ]
                  }
                  image={highPerfImg}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Programmability"
                  featureDescs={
                    [
                      "Turing-complete",
                      "Stateless and stateful"
                    ]
                  }
                  image={programImg}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Security"
                  featureDescs={
                    [
                      "Pure and deterministic",
                      "Decidable and predictable"
                    ]
                  }
                  image={secureImg}
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </Container>
      {/* lower part in the yellow background color */}
      <ArgonBox bgColor="yellow" height={pxToRem((mb + 1) * pixResolution)}></ArgonBox>
    </>
  );
}

export default ContractSection;