/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";

// Images
import pricingBG from "assets/images/pricing_page_bg.png";

function Header({ }) {
  return (
    <ArgonBox position="relative" >
      <ArgonBox
        position="absolute"
        component="img"
        src={pricingBG}
        sx={{ m: 0 }}
        alt="background"
        width="100%"
        bgColor="yellow">
      </ArgonBox>
    </ArgonBox>
  );
}

// Typechecking props for the Header
Header.propTypes = {

};

export default Header;
