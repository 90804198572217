/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonInput from "base-components/ArgonInput";
import ArgonButton from "base-components/ArgonButton";

// Authentication layout components
import PortalLayout from "pages/portal/components/Layout";
import Banner from "./BannerSection";
import ContractSection from "./ContractSection";
import ExampleSection from "./ExampleSection";

import { styled } from "@mui/material/styles";
import PlatformSection from "./PlatformSection";
import PartnerSection from "./PartnerSection";
import TokensSection from "./TokensSection";

function Landing() {

  return (
    <PortalLayout>
      <ArgonBox pt={{ xs: 3, lg: 15 }}>
        <Banner />
      </ArgonBox>
      <ArgonBox pt={{ xs: 3, lg: 15 }}>
        <ContractSection />
      </ArgonBox>
      <ArgonBox pt={{ xs: 2, lg: 5 }} pb={{ xs: 3, lg: 15 }} bgColor="yellow">
        <ExampleSection />
      </ArgonBox>
      <ArgonBox pt={{ xs: 2, lg: 5 }} bgColor="black">
        <TokensSection />
      </ArgonBox>
      <ArgonBox pt={{ xs: 3, lg: 15 }} bgColor="black">
        <PlatformSection />
      </ArgonBox>
      <ArgonBox pt={{ xs: 3, lg: 15 }} bgColor="black">
        <PartnerSection />
      </ArgonBox>
    </PortalLayout>
  );
}

export default styled(Landing)({
});
