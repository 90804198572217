/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonInput from "base-components/ArgonInput";
import ArgonButton from "base-components/ArgonButton";
import ArgonSnackbar from "base-components/ArgonSnackbar";

// Authentication layout components
import PortalLayout from "pages/portal/components/Layout";

import { styled } from "@mui/material/styles";
import { Container, Grid, Link } from "@mui/material";
import MuiLink from "@mui/material/Link";

import bannerImage from "assets/images/banner-faucet.png";

function UsageTmplate({ desc }) {
  return (
    <ArgonBox pt={2} display="flex" alignItems="center">
      <ArgonBox display="flex" bgColor="yellow" width="8px" height="8px" mr={2} />
      <ArgonTypography display="flex" variant="d9" textAlign="left">
        {desc}
      </ArgonTypography>
    </ArgonBox>
  )
}

function Faucet({ className }) {

  const [address, setAddress] = useState("");
  const [successTxId, setSuccessTxId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const openSuccessNotify = (txId) => setSuccessTxId(txId);
  const closeSuccessNotify = () => setSuccessTxId("");
  const openErrorNotify = (msg) => setErrorMsg(msg);
  const closeErrorNotify = () => setErrorMsg("");

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleGetBSV = () => {
    postData('https://witnessonchain.com/v1/faucet/tbsv', { address: address, channel: "scrypt.io" })
    .then(data => {
      if(data.code  === 0) {
        openSuccessNotify(data.txid)
      } else if(data.code === 20) {
        openErrorNotify(`Address ${address} is still on cooldown!`)
      } else {
        openErrorNotify(data.message)
      }
    })
    .catch(e => {
      openErrorNotify(e.message)
    })
  }

  const renderSuccessNotify = (
    <ArgonSnackbar
      color="success"
      icon="check"
      title="Get BSV successfully!"
      dateTime=""
      content={(
        <>
          You can check the funding transaction&nbsp;
          <u><MuiLink href={`https://test.whatsonchain.com/tx/${successTxId}`}>here</MuiLink></u>
        </>
      )}
      open={successTxId !== ""}
      close={closeSuccessNotify}
      light
    />
  );

  const renderErrorNotify = (
    <ArgonSnackbar
      color="error"
      icon="warning"
      title="Get BSV failed!"
      dateTime=""
      content={errorMsg}
      open={errorMsg !== ""}
      close={closeErrorNotify}
      light
    />
  );;

  return (
    <PortalLayout title="Faucet" className={className}>
      <Container>
        <Grid container display="flex" justifyContent="space-between" alignItems="center" py={{ sm: 2, lg: 5 }}>
          <Grid item xs={12} lg={7}>
            <ArgonTypography variant="h3">
              What is a testnet faucet?
            </ArgonTypography>
            <ArgonTypography variant="body1" pt={3}>
              A testnet faucet is a service that provides users with free testnet tokens for use in testing and development, allowing developers to test their applications and smart contracts before deploying them to the main network.
              The purpose of testnet tokens is to simulate the behavior of the main network without risking real funds.
            </ArgonTypography>
          </Grid>
          <Grid item xs={12} lg={5} display="flex" flexDirection="row-reverse">
            <ArgonBox display="flex">
              <ArgonBox src={bannerImage} component="img" title="banner icon" width="100%" />
            </ArgonBox>
          </Grid>
        </Grid>
        <ArgonBox display="flex" sx={{ backgroundColor: '#151515' }} alignItems="center" justifyContent="space-between" flexDirection="column" py={10}>
          <ArgonTypography variant="h3">
            Get testnet BSV from sCrypt faucet
          </ArgonTypography>
          <ArgonBox pt={5} display="flex">
            <ArgonBox display="flex">
              <ArgonInput
                placeholder="Enter your testnet address" size="large"
                onChange={(e) => setAddress(e.target.value)}
                sx={{
                  minWidth: 600, 
                  // borderRadius: '2px', borderColor: '#3B3B3B', backgroundColor: 'white !important'
                }}
              />
            </ArgonBox>
            <ArgonBox ml={2} display="flex" flexDirection="row-reverse">
              <ArgonButton onClick={handleGetBSV} size="large">Get BSV</ArgonButton>
              {renderSuccessNotify}
              {renderErrorNotify}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox pt={6}>
          <ArgonTypography variant="h3">
            How to use the faucet?
          </ArgonTypography>
          <UsageTmplate
            desc="You can request testnet funds twice every 24 hours."
          />
          <UsageTmplate
            desc="You can donate testnet coins to this faucet with the address: mnai8LzKea5e3C9qgrBo7JHgpiEnHKMhwR"
          />
          <UsageTmplate
            desc={
              <>
                Alternatively, you can also use these faucets:&nbsp;
                <MuiLink href="https://witnessonchain.com/faucet/tbsv" target="_blank" rel="noreferrer" variant="d9">
                  <u>Witnessonchain Faucet</u>
                </MuiLink>
                &nbsp;or&nbsp;
                <MuiLink href="https://testnet.satoshisvision.network/" target="_blank" rel="noreferrer" variant="d9">
                  <u>Satoshis Vision Faucet</u>
                </MuiLink>
              </>
            }
          />
        </ArgonBox>

      </Container>
    </PortalLayout>
  );
}

export default styled(Faucet)({
  "& h3": {
    color: "white",
  }
});
