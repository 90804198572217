// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";
import ArgonTypography from "base-components/ArgonTypography";

// Images
import bgImage from "assets/images/bg-subscription.jpg";

import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { formatDate, handleUnauthorized } from "utils";

import {
  useArgonController,
} from "context";
import ArgonBadge from "base-components/ArgonBadge";


function formatPrice(price) {
  if (!price) return '-';
  return `\$${price}`;
}

function Subscription({ color }) {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState({
    grade: "starter",
    status: "none",
    price: 0,
    cancel_at: 0,
    renewDate: 0,
    renewAmount: 0,
    due_at: 0,
    pay_invoice: undefined
  });


  const [annually, setAnnually] = useState(false)

  useEffect(() => {
    if (user) {
      fetch(`/api/billing/subscription`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => handleUnauthorized(res, dispatch, navigate))
        .then(res => {
          const { statusCode, data } = res;
          if (statusCode === 0) {
            setSubscription(data)
          }
        })
    }
  }, []);

  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { linearGradient, rgba },
        boxShadows: { xl },
      }) => ({
        background: gradients[color]
          ? `${linearGradient(
            rgba(gradients[color].main, 0.8),
            rgba(gradients[color].state, 0.8)
          )}, url(${bgImage})`
          : `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        boxShadow: xl,
      })
      }
    >
      <ArgonBox p={3}>
        <ArgonBox color="white" display="flex" justifyContent="space-between">
          <Icon fontSize="large" color="primary">card_membership</Icon>
          <ArgonBox display="flex" justifyContent="flex-end" width="20%">
            <ArgonButton size="large" component={Link} to="/pricing">
              <Icon fontSize="large" >rocket_launch</Icon>
              &nbsp;&nbsp;Upgrade
            </ArgonButton>
          </ArgonBox>

        </ArgonBox>
        <ArgonTypography
          variant="h3"
          color="white"
          fontWeight="medium"
          sx={{ mt: 5, pb: 1 }}
        >
          {subscription.grade.toUpperCase()}&nbsp;&nbsp;PLAN
        </ArgonTypography>
        <ArgonTypography color="white" sx={{ mb: 5 }}>
          {
            subscription.price ? `${formatPrice(subscription.price / 100)} / month (billed ${annually ? 'annually' : 'monthly'})` : 'free of charge'
          }
        </ArgonTypography>
        <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
          <ArgonBox display="flex" alignItems="center">
            <ArgonBox mr={6} lineHeight={1}>
              <ArgonTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Renew At
              </ArgonTypography>
              <ArgonTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {formatDate(subscription.renewDate * 1000)}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={6} lineHeight={1}>
              <ArgonTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Upcoming Payment
              </ArgonTypography>
              <ArgonTypography variant="h6" color="white" fontWeight="medium">
                {formatPrice(subscription.renewAmount / 100)}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mr={6} lineHeight={1}>
              <ArgonTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Plan ending on
              </ArgonTypography>
              <ArgonTypography variant="h6" color="white" fontWeight="medium">
                {formatDate(subscription.cancel_at * 1000)}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox display="flex" justifyContent="flex-end">

            {

              subscription.grade === "starter" ? (<></>) : (
                subscription.status === "past_due" ? (
                  <>
                    <ArgonBadge
                      variant="contained"
                      color="error"
                      size="md"
                      badgeContent={`Due at: ${formatDate(subscription.due_at * 1000)}`}
                      container
                    />
                    <ArgonButton size="small" component="a"
                      sx={{ ml: 2 }}
                      href={subscription.pay_invoice}
                      target="_blank"
                      rel="noreferrer"
                      variant="outlined">
                      Pay Invoice
                    </ArgonButton>
                  </>
                ) : (
                  <ArgonButton size="small" component={Link} to="/api/billing/manage" target="_blank" variant="outlined">
                    Manage Subscription
                  </ArgonButton>
                )
              )}
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of Subscription
Subscription.defaultProps = {
  color: "dark",
};

// Typechecking props for the Subscription
Subscription.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
};

export default Subscription;
