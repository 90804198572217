import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import PricingCards from "../components/PricingCards";

function Pricing({ className }) {

  const [prices, setPrices] = useState({});


  function getPrices() {
    fetch('/api/billing/prices').then(res => res.json()).then(response => {

      const { statusCode, data } = response;
      if (statusCode === 0) {
        setPrices(data)
      }
    }).catch(error => {
      console.error('get prices error', error)
    });
  }

  useEffect(() => {
    getPrices()

  }, [])

  return (
    <ArgonBox width="100%" display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" >
      <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }} >
        <Grid item xs={10} lg={5}>
          <ArgonBox mt={20} mb={1}>
            <ArgonTypography variant="h2" color="white">
              See our pricing
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mb={2}>
            <ArgonTypography variant="body2" color="text">
              You have Free Unlimited Updates and Premium Support on each package.
            </ArgonTypography>
          </ArgonBox>
        </Grid>
      </Grid>
      <PricingCards prices={prices?.prices || [0, 49, 199, 0]} discounts={prices?.discounts || 0} />
      <ArgonBox mt={8}>
        <ArgonTypography color="info" sx={{ textAlign: "center" }}>
          Credit card details are never stored on nor reach our servers. Payment data is handled by Stripe.
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

export default styled(Pricing)({

});