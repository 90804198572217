// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Link } from "@mui/material";

import emailImg from "assets/images/logo_Email.png";
import mediumImg from "assets/images/logo_Medium.png";
import githubImg from "assets/images/logo_Github.png";
import telegramImg from "assets/images/logo_Telegram.png";
import slackImg from "assets/images/logo_Slack.png";
import twitterImg from "assets/images/logo_Twitter.png";
import wechatImg from "assets/images/logo_Wechat.png";
import youtubeImg from "assets/images/logo_Youtube.png";
import CopyRight from "components/Footer/Copyright";

function Footer({ className }) {
  return (
    <Container className={className}>
      <ArgonBox display="flex" justifyContent="center" sx={{ flexWrap: 'wrap' }} pt={{ sm: 3, lg: 15 }}>
        <Link href="https://t.me/joinchat/GwaRAxKT16JjXyHt5PuhHw">
          <ArgonBox src={telegramImg} component="img" title="Telegram" />
        </Link>
        <Link href="https://join.slack.com/t/scryptworkspace/shared_invite/zt-1zeoorxyt-zggK4fGc31swt94~1X7M8w">
          <ArgonBox src={slackImg} component="img" title="Slack" />
        </Link>
        <Link href="https://scryptplatform.medium.com">
          <ArgonBox src={mediumImg} component="img" title="Medium" />
        </Link>
        <Link href="https://twitter.com/sCryptPlatform">
          <ArgonBox src={twitterImg} component="img" title="Twitter" />
        </Link>
        <Link href="mailto:support@scrypt.io">
          <ArgonBox src={emailImg} component="img" title="Email" />
        </Link>
        <Link href="https://github.com/sCrypt-Inc">
          <ArgonBox src={githubImg} component="img" title="Github" />
        </Link>
        <Link href="https://www.youtube.com/@sCryptPlatform">
          <ArgonBox src={youtubeImg} component="img" title="Youtube" />
        </Link>
        <Link href="/img/wechat-qrcode.jpg">
          <ArgonBox src={wechatImg} component="img" title="Wechat" />
        </Link>
      </ArgonBox>
      <ArgonBox width="100%" display="flex" alignItems="center" flexDirection="column" py={2}>
        <CopyRight />
      </ArgonBox>
    </Container>
  )
}

export default styled(Footer)({
  a: {
    margin: '0 16px'
  },
  img: {
    height: '32px'
  }
});
