// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";

import ftImg from 'assets/images/icon-ft.png';
import nftImg from 'assets/images/icon-nft.png';
import apiImg from 'assets/images/icon-api.png';
import contractImg from 'assets/images/icon-contract.png';
import itgImg from 'assets/images/icon-integration.png';
import tokenomicsImg from 'assets/images/icon-tokenomics.png';

import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

import pxToRem from "assets/theme/functions/pxToRem";

function FeatureTemplate({ className, title, image, bgColor }) {
  return (
    <ArgonBox className={className} display="flex" alignItems="center" flexWrap="wrap" bgColor={bgColor}>
      <ArgonBox display="flex" sx={{ flex: "1 1 100%" }} justifyContent="space-between" py={2}>
        <ArgonBox display="flex">
          <ArgonTypography variant="h4" color="white" px={2} py={1}>
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" component="img" src={image} height="200px"/>
      </ArgonBox>
    </ArgonBox>
  )
}

const FeatureCard = styled(FeatureTemplate)({
  borderRadius: '4px',
  margin: '8px',
})

function TokensSection({ className }) {
  const location = useLocation();
  const id = "tokens-section";
  const pixResolution = 50;
  const ref = useRef(null);
  const [mt, setMT] = useState(0);
  const [mb, setMB] = useState(0);

  const scrollIntoView = () => {
    if (window.location.hash === `#${id}`) {
      document.querySelector(`#${id}`).scrollIntoView();
    }
  }

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        const height = ref.current.offsetHeight;
        const offset = height * 5 / 6;
        setMT(Math.floor(offset / pixResolution));
        setMB(Math.floor((height - offset) / pixResolution));
        return;
      }
    }

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    }
  }, []);

  useEffect(() => {
    setTimeout(scrollIntoView, 50);
  }, [location]);

  return (
    <>
      {/* upper part in the black background color */}
      <Container id={id} >
        <ArgonBox ref={ref} display="flex" className={className}  justifyContent="space-between" alignItems="center" flexDirection="column" >
          <ArgonBox display="flex" alignItems="center" pt={3} px={2}>
            <ArgonTypography variant="h2" color="white" textTransform="uppercase">
              Tokens
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" alignItems="center" p={2}>
            <ArgonTypography variant="subtitle1" color="white">
              Full support for 1SAT Ordinals protocol
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" py={2} width="calc(100% - 3rem)">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Ordinals NFTs"
                  image={nftImg}
                  bgColor="#4B3978"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="BSV-20 Fungible Tokens"
                  image={ftImg}
                  bgColor="#255377"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="sCrypt Smart Contracting Capabilities"
                  image={contractImg}
                  bgColor="#356C40"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FeatureCard
                  title="Smooth Integration With sCrypt SDK"
                  image={itgImg}
                  bgColor="#602C2F"
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </Container>
    </>
  );
}

export default TokensSection;