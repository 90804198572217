/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

import { hashPassword, isValidEmail, isValidPassword, isValidRepeatPassword } from "../utils";
import FormField from "components/FormField";
import Swal from "sweetalert2";

// Image
import bgImage from "assets/images/banner-signup.png";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setUser
} from "context";

function SignUp() {
  const [controller, dispatch] = useArgonController();
  const { user } = controller;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [emailErrorMessage, setEmailErrorMessage] = useState(null)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null)
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState(null)

  const emailInvalidMessage = 'Invalid email'
  const passwordInvalidMessage = 'Password requires a combination of at least 8 letters and numbers'
  const repeatPasswordInvalidMessage = 'The password and confirmation password do not match'

  const validateFormData = () => isValidEmail(email) && isValidPassword(password) && isValidRepeatPassword(repeatPassword, password)

  const formSubmit = async (e) => {
    e.preventDefault();

    if (!validateFormData()) {
      return
    }

    fetch('/api/auth/register', {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        password: await hashPassword(password)
      }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(async (res) => {
      const response = await res.json();
      if (response.statusCode === 0) {
        setUser(dispatch, response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      } else if (response.statusCode === 10006) {
        setEmailErrorMessage("User exists")
      } else {
        setEmailErrorMessage(response.errorMsg)
      }
    }).catch(error => {
      console.error('Error:', error)
      setEmailErrorMessage('Sign up failed')
    });

    if(gtag) {
      gtag('event', 'sign_up', {
        'event_category': 'engagement',
        'event_label': 'sign_up'
      });
    }
  }

  return (
    <IllustrationLayout
      title="Sign Up"
      description="Enter your email and password to register"
      illustration={{
        image: bgImage,
        title: '"Your journey starts here"',
        description:
          "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      }}
    >
      {user && (user.verified === false) && (<Navigate to="/verify-email" replace={true} />)}
      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <FormField type="email" placeholder="Email" size="large" label={emailErrorMessage || ''} onChange={e => {
            const value = e.target.value
            setEmail(value)
            setEmailErrorMessage(isValidEmail(value) ? '' : emailInvalidMessage)
          }} />
        </ArgonBox>
        <ArgonBox mb={2}>
          <FormField type="password" placeholder="Password" size="large" label={passwordErrorMessage || ''} onChange={e => {
            const value = e.target.value
            setPassword(value)
            setPasswordErrorMessage(isValidPassword(value) ? '' : passwordInvalidMessage)
            setRepeatPasswordErrorMessage(isValidRepeatPassword(repeatPassword, value) ? '' : repeatPasswordInvalidMessage)
          }} />
        </ArgonBox>
        <ArgonBox mb={2}>
          <FormField type="password" placeholder="Repeat Password" size="large" label={repeatPasswordErrorMessage || ''} onChange={e => {
            const value = e.target.value
            setRepeatPassword(value)
            setRepeatPasswordErrorMessage(isValidRepeatPassword(value, password) ? '' : repeatPasswordInvalidMessage)
          }} />
        </ArgonBox>
        <ArgonBox mt={4} mb={1}>
          <ArgonButton size="large" fullWidth onClick={formSubmit} disabled={!validateFormData()}>
            Sign Up
          </ArgonButton>
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Already have an account?{" "}
            <ArgonTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Sign In
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default SignUp;
