/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
// Authentication layout components
import PortalLayout from "pages/portal/components/Layout";


import { styled } from "@mui/material/styles";

import ArgonTypography from "base-components/ArgonTypography";
import pricingBG from "assets/images/pricing_page_bg.png";
import { Container, Grid, Link } from "@mui/material";
import { Helmet } from 'react-helmet';
import ArgonButton from "base-components/ArgonButton";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
function PayCanceled() {

    let [searchParams, setSearchParams] = useSearchParams()



    useEffect(() => {

    }, [])

    return (<>
        <Helmet>
            <script
                src="https://js.stripe.com/v3/"
                crossorigin="anonymous"
                async
            ></script>
        </Helmet>

        <PortalLayout >

            <ArgonBox position="relative" >

                <ArgonBox
                    position="absolute"
                    component="img"
                    src={pricingBG}
                    sx={{ m: 0 }}
                    alt="background"
                    width="100%"
                    bgColor="yellow">
                </ArgonBox>

                <ArgonBox pt={{ xs: 3, lg: 15 }}>
                    <Container>
                        <ArgonBox width="100%" display="flex" alignItems="center" flexDirection="column" pb={3}>
                            <ArgonBox display="flex">
                                <ArgonTypography variant="h2" color="white">
                                    payment canceled
                                </ArgonTypography>
                            </ArgonBox>
                        </ArgonBox>


                        <ArgonBox width="100%" display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" >
                            <ArgonBox mt={10} px={{ xs: 1, sm: 0 }} sx={{ height: 600 }}>
                                <ArgonButton
                                    component="a"
                                    href="/account/billing"
                                    size="small"
                                    fullWidth>
                                    Go to Dashboard
                                </ArgonButton>
                            </ArgonBox>
                        </ArgonBox>
                    </Container>
                </ArgonBox>
            </ArgonBox>
        </PortalLayout>
    </>);
}

export default styled(PayCanceled)({
});
