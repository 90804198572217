/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import FaqCollapse from "../FaqCollapse";
import { Link } from "@mui/material";
import ArgonButton from "base-components/ArgonButton";
import { Icon } from "@mui/material";

// Faq page components

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <ArgonBox mt={8} mb={8}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <ArgonTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            FAQ
          </ArgonTypography>

        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title="How do I get started?"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            To get started with sCrypt, we encourage you to check out our example for
            <Link href="https://docs.scrypt.io/tutorials/voting" target="_blank">
              <ArgonTypography color="primary" display="inline" variant="d9"> how to create your first dApp </ArgonTypography>
            </Link>
          </FaqCollapse> <FaqCollapse
            title="Can I upgrade my plan?"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            Yes, you can upgrade your plan at any time!
          </FaqCollapse>
          <FaqCollapse
            title="Can I downgrade my plan?"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            Yes, you can downgrade your plan at any time, and your plan will be downgraded automatically at the beginning of your next billing period.
            <br/>
            <br/>
            You can also cancel your subscription through the "manage subscription" link in
            <Link to="/account/billing">
              <ArgonTypography color="primary" display="inline" variant="d9"> Billing </ArgonTypography>
            </Link>
            page.
          </FaqCollapse>
          <FaqCollapse
            title="What payment methods does sCrypt accept?"
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            sCrypt accepts payment from all major debit and credit cards.
          </FaqCollapse>
          <FaqCollapse
            title="What happens if I hit API limits?"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            Each of the plans has a request-per-second limit. If your application sends more requests than the specified limit within one second, our server will reject some of those requests.
            <br />
            <br />
            The Starter plan has daily and monthly limits. Requests that exceed the specified limit within the specified time frame are simply rejected. You can either wait for it to be reset in the next period or upgrade your plan to increase the limit immediately.
            <br />
            <br />
            Aside from the Starter plan, those plans have no daily limit but still have a monthly limit. If you reach the limit, we will continue to serve those requests, but with an additional charge per million requests based on your plan.
          </FaqCollapse>
        </Grid>
      </Grid>
    </ArgonBox>
  );
}

// Typechecking props for the FaqCollapse
Faq.propTypes = {

};

export default Faq;
