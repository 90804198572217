/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 PRO MUI layouts
import SignIn from "pages/authentication/sign-in";
import ApiKeys from "pages/account/api-keys";
import SignUp from "pages/authentication/sign-up";
import VerifyEmail from "pages/authentication/verify-email";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ResetPassword from "pages/authentication/reset-password";
import ResetPasswordSubmit from "pages/authentication/reset-password-submit";
import ConfirmEmail from "pages/authentication/confirm-email";
import Landing from "pages/portal/Landing";
import Faucet from "pages/portal/Faucet";
import Usage from "pages/account/usage";
import Pricing from "pages/portal/Pricing";
import PaySuccess from "pages/payment/PaySuccess";
import PayCanceled from "pages/payment/PayCanceled";
import Billing from "pages/account/billing";
import PayConfirm from "pages/payment/PayConfirm";
import Webhooks from "pages/account/webhooks";
import CreateWebhook from "pages/account/webhooks/create";
import EditWebhook from "pages/account/webhooks/edit";

const routes = [
  {
    type: "collapse",
    name: "Account",
    key: "account",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-ui-04" />,
    collapse: [
      {
        name: "API Keys",
        key: "api-keys",
        route: "/account/api-keys",
        component: <ApiKeys />,
      },
      {
        name: "Webhooks",
        key: "webhooks",
        route: "/account/webhooks",
        component: <Webhooks />,
      },
      {
        name: "Usage",
        key: "usage",
        route: "/account/usage",
        component: <Usage />,
      },
      {
        name: "Billing",
        key: "billing",
        route: "/account/billing",
        component: <Billing />,
      }
    ],
  },
  {
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    component: <ResetPassword />,
  },
  {
    name: "Reset Password Submit",
    key: "reset-password-submit",
    route: "/reset-password-submit",
    component: <ResetPasswordSubmit />,
  },
  {
    name: "Verify Email",
    key: "verify-email",
    route: "/verify-email",
    component: <VerifyEmail />,
  },
  {
    name: "Confirm Email",
    key: "confirm-email",
    route: "/confirm-email",
    component: <ConfirmEmail />,
  },
  {
    name: "Landing",
    key: "landing",
    route: "/",
    component: <Landing />,
  },
  {
    name: "Faucet",
    key: "faucet",
    route: "/faucet",
    component: <Faucet />,
  },
  {
    name: "Pricing",
    key: "pricing",
    route: "/pricing",
    component: <Pricing />,
  },
  {
    name: "PaySuccess",
    key: "paysuccess",
    route: "/payment/success",
    component: <PaySuccess />,
  },
  {
    name: "PayCanceled",
    key: "paycanceled",
    route: "/payment/canceled",
    component: <PayCanceled />,
  }, 
  {
    name: "PayConfirm",
    key: "payconfirm",
    route: "/payment/confirm",
    component: <PayConfirm />,
  },
  {
    name: "CreateWebhook",
    key: "create-webhook",
    route: "/account/webhooks/create",
    component: <CreateWebhook />,
  },
  {
    name: "EditWebhook",
    key: "edit-webhook",
    route: "/account/webhooks/:webhookId/edit",
    component: <EditWebhook />,
  }
];

export default routes;
