/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import FormField from "components/FormField";
import ArgonButton from "base-components/ArgonButton";
// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/banner-signup.png";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setUser
} from "context";
import { hashPassword } from "../utils";

function SignIn() {
  const navigate = useNavigate();
  const REMEMBER = 'remember'
  const [rememberMe, setRememberMe] = useState(false);
  const [controller, dispatch] = useArgonController();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [errorMessage1, setErrorMessage1] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  useEffect(() => {
    const savedEmail = localStorage.getItem(REMEMBER)
    setRememberMe(!!savedEmail)
    if (savedEmail) {
      setEmail(savedEmail)
    }

    getProfile()
  }, [])

  let params = new URL(document.location).searchParams;
  let redirect_uri = params.get("redirect_uri");


  function saveUser(user, noUIupdate) {
    if (!noUIupdate) {
      setUser(dispatch, user)
    }

    localStorage.setItem('user', JSON.stringify(user))
    if (rememberMe) {
      localStorage.setItem(REMEMBER, user.email)
    }
  }

  function getProfile() {
    fetch('/api/auth/profile').then(res => res.json()).then(response => {

      const {statusCode, data} = response;
      if (statusCode === 0) {
        saveUser(data, true)
        
        if (data.verified) {

          if(redirect_uri) {
            window.location.href = redirect_uri
          } else {
            navigate("/account/api-keys")
          }

        } else {
          navigate("/verify-email")
        }
      }
    }).catch(error => {
      console.error('getProfile error', error)
    });
  }


  function _formPost(body, fn) {
    fetch('/api/auth/login', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({ 'Content-Type': 'application/json' })
    }).then(res => res.json()).then(response => {
      console.log(response)
      if (response.statusCode === 0) {
        fn(response.data)
      } else if (response.statusCode === 10000) {
        setErrorMessage1("User not exists.")
      } else if (response.statusCode === 10005) {
        setErrorMessage2("Invalid password.")
      } else {
        setErrorMessage2(response.errorMsg)
      }
    }).catch(error => {
      setErrorMessage2("Login failed.");
      console.error('Error:', error)
    });
  }

  const formSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage1('')
    setErrorMessage2('')

    if (!rememberMe) {
      localStorage.removeItem(REMEMBER)
    }

    _formPost({
      email: email,
      password: await hashPassword(password),
      passwordHashed: true,
    }, (user) => {
      if (user.passwordHashed) {

        if (redirect_uri) {

          if (user.verified) {
            saveUser(user, true)
            window.location.href = redirect_uri
          } else {
            navigate("/verify-email")
          }

        } else {

          if (user.verified) {
            saveUser(user)
            navigate("/account/api-keys")
          } else {
            navigate("/verify-email")
          }

        }
      } else {
        _formPost({
          email: email,
          password: password,
          passwordHashed: false,
        }, (user) => {


          if (redirect_uri) {
            if (user.verified) {
              saveUser(user, true)
              window.location.href = redirect_uri
            } else {
              navigate("/verify-email")
            }

          } else {

            if (user.verified) {
              saveUser(user)
              navigate("/account/api-keys")
            } else {
              navigate("/verify-email")
            }
          }
        })
      }
    })
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: bgImage,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <FormField value={email} type="email" placeholder="Email" size="large" onChange={e => setEmail(e.target.value)} label={errorMessage1} />
        </ArgonBox>
        <ArgonBox mb={2}>
          <FormField type="password" placeholder="Password" size="large" onChange={e => setPassword(e.target.value)} label={errorMessage2} />
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={4} mb={1}>
          <ArgonButton size="large" fullWidth onClick={formSubmit}>
            Sign In
          </ArgonButton>
        </ArgonBox>
        <ArgonBox mt={3} mb={1}>
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <ArgonTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Sign up
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={3} mb={1}>
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Forgot Password?{" "}
            <ArgonTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Reset
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default SignIn;
