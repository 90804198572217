import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function MiniUsageCard({ title, total, used }) {
  const used_ = used || 0;
  const total_ = total || 9999999999;
  const percentage = Math.round((used_ / total_) * 100);
  const color = percentage > 90 ? "error" : "success";
  return (
    <MiniStatisticsCard
      title={{ text: title, fontWeight: "medium" }}
      count={used_}
      percentage={{ color, value: percentage, text: `${percentage}% used` }}
      icon={{ color: "primary", component: "data_usage" }}
      display="flex"
    />
  )
}

MiniUsageCard.defaultProps = {
}

MiniUsageCard.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MiniUsageCard;