/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";

// Billing page components
import Invoice from "pages/account/billing/components/Invoice";

import { useEffect, useState } from "react";


function Invoices() {

  const [invoices, setInvoices] = useState([]);
  useEffect(() => {

    fetch(`/api/billing/invoices`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        const { statusCode, data } = res;

        if(statusCode === 0) {
          setInvoices(data)
        } else {
          setInvoices([])
        }
      })
      .catch(e => {
        console.error('fetch invoices failed:', e)
        setInvoices([])
      })
  }, [])

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <ArgonTypography variant="h6" fontWeight="medium">
          Invoices
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {
            invoices.map(invoice => (
              <Invoice
                key={`${invoice.number}`}
                date={new Date(invoice.created * 1000).toDateString()}
                id={`${invoice.number}`}
                price={`\$${invoice.total / 100}`}
                url={invoice.invoice_pdf} />
            ))
          }

        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default Invoices;
