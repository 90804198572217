/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import iconCheck from "assets/images/icon_check.png";
import pxToRem from "assets/theme/functions/pxToRem";

function CheckIcon({ ...rest }) {
  return (
    <ArgonBox src={iconCheck} component="img" title="Check" width={pxToRem(24)} height={pxToRem(24)} aaa="aaa" bgColor="transparent" />
  );
}



export default CheckIcon;
