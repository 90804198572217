// react-router components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonBadge from "base-components/ArgonBadge";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";
import { useEffect, useState } from "react";

import { getDefaultApiKey, handleUnauthorized } from "utils";

// sweetalert2 components
import Swal from "sweetalert2";


function WebhookBrief({ network, id, url, contractId, desc, status, statusNote, updatedAt }) {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const navigate = useNavigate();

  const [moreActionsMenu, setMoreActionsMenu] = useState(null);
  const openMoreActionsMenu = (event) => setMoreActionsMenu(event.currentTarget);
  const closeMoreActionsMenu = () => setMoreActionsMenu(null);

  const [curStatus, setCurStatus] = useState(status);
  const [curStatusNote, setCurStatusNote] = useState(statusNote);

  const renderMenu = (state, close) =>
  (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >

      <MenuItem
        onClick={() => {
          updateWebhookStatus(id, { status: curStatus === "active" ? "paused" : "active" });
          close();
        }}
      >
        {curStatus === "active" ? 'Pause' : 'Activate'}
      </MenuItem>
      <MenuItem onClick={() => { close(); navigate(`/account/webhooks/${id}/edit?network=${network}`)}} >Edit</MenuItem>
      <MenuItem
        onClick={() => {
          Swal.mixin({
            customClass: {
              confirmButton: "button button-success",
              cancelButton: "button button-error",
            },
            buttonsStyling: false,
          })
            .fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
            })
            .then((result) => {
              if (result.isConfirmed) {
                updateWebhookStatus(id, { status: "deleted" });
              }
            });
          close();
        }}
      >Delete</MenuItem>
    </Menu>
  )

  const updateWebhookStatus = (webhookId, updates) => {
    fetch(`/api/webhooks/${webhookId}/update?network=${network}&apiKey=${getDefaultApiKey(user, network)}`, {
      method: 'Post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...updates, webhookId, statusNote: '' })
    })
      .then(res => handleUnauthorized(res, dispatch, navigate))
      .then(res => {
        if (res.statusCode === 0) {
          setCurStatus(res.data.status);
          setCurStatusNote(res.data.statusNote);
        } else {
          console.log('update webhook failed')
        }
      })
  }

  return (
    <ArgonBox mb={2} sx={{ listStyle: "none" }}>
      <Divider />
      <ArgonBox display="flex" alignItems="center">
        <ArgonBox color="text" opacity={0.6} display="flex" alignItems="center">
          <Icon fontSize="default">webhook</Icon>
        </ArgonBox>
        <ArgonBox ml={1} lineHeight={1}>
          <ArgonTypography variant="h6" fontWeight="medium">
            {url}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center" ml="auto" color="secondary" pr={3} lineHeight={0}>
          <ArgonBox display="flex" alignItems="center" sx={{ pr: 2 }}>
            <ArgonBadge
              variant="contained"
              size="xs"
              badgeContent={curStatus}
              color={curStatus === "active" ? "success" : "error"}
              container
            />
            {
              curStatusNote && (
                <Tooltip title={curStatusNote} sx={{ ml: 0.5 }}>
                  <Icon fontSize="small">help</Icon>
                </Tooltip>
              )
            }
          </ArgonBox>
          <Icon fontSize="default" sx={{ cursor: "pointer" }} onClick={openMoreActionsMenu}>
            more_vert
          </Icon>
        </ArgonBox>
        {curStatus !== 'deleted' ? renderMenu(moreActionsMenu, closeMoreActionsMenu) : ''}
      </ArgonBox>
      <ArgonBox px={{ sm: 1, md: 4 }}>
        <ArgonBox lineHeight={1} mb={{ xs: 1, sm: 0 }}>
          <ArgonTypography
            display="inline-block"
            variant="h6"
            fontWeight="medium"
            color="secondary"
            sx={{ pr: 2 }}
          >
            Id:
          </ArgonTypography>
          <ArgonTypography variant="caption" color="text">
            {id}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mx={{ xs: 0, sm: "auto" }} lineHeight={1}>
          <ArgonTypography
            display="inline-block"
            variant="h6"
            fontWeight="medium"
            color="secondary"
            sx={{ pr: 2 }}
          >
            Contract Id:
          </ArgonTypography>
          <ArgonTypography variant="caption" color="text">
            {contractId}
          </ArgonTypography>
        </ArgonBox>
        {
          desc && (
            <ArgonBox ml={{ xs: 0, sm: "auto" }} mb={{ xs: 1, sm: 0 }} lineHeight={1}>
              <ArgonTypography
                display="inline-block"
                variant="h6"
                fontWeight="medium"
                color="secondary"
                sx={{ pr: 2 }}
              >
                Description:
              </ArgonTypography>
              <ArgonTypography variant="caption" color="text">
                {desc}
              </ArgonTypography>
            </ArgonBox>
          )
        }
      </ArgonBox>
    </ArgonBox>
  )
}

export default WebhookBrief;