import { setUser } from "context";
import Swal from "sweetalert2";

export function handleUnauthorized(response: Response, dispatch: any, navigate: any) {
  if (response.status === 401) {
    Swal.fire({
      title: "Session Expired",
      html: "You will be redirected to the login page in 5 seconds.",
      timer: 5000,
      timerProgressBar: true,
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      icon: "error",
      willClose: () => {
        setUser(dispatch, null);
        localStorage.removeItem('user');
        navigate('/sign-in');
      },
    });
    return Promise.reject('Session Expired');
  }

  return Promise.resolve(response.json());
}

export function formatDate(date?: Date) {
  if (!date) {
    return '-';
  }

  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('/');
}

export function getDefaultApiKey(user: any, network: string) {
  return user?.apiKeys?.filter((apiKey: string) => apiKey.startsWith(network))[0];
}