// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Grid, Link } from "@mui/material";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

import codeImgTuring from "assets/images/code-turing.png";
import codeImgP2PKH from "assets/images/code.png";
import codeImgSecureEnclave from "assets/images/code-secureEnclave.png";
import codeImgZKP from "assets/images/code-zkp.png";

import exIcon1 from "assets/images/banner-example-01.png";
import exIcon2 from "assets/images/banner-example-02.png";

function ExampleTemplate({ className, title, href, desc, image }) {
  return (
    <ArgonBox className={className} px={{ sx: 0, lg: 2 }}>
      <ArgonBox pt={2} textAlign="left">
        <MuiLink href={href} target="_blank" rel="noreferrer" variant="h3">
          <u>{title}</u>
        </MuiLink>
      </ArgonBox>
      {/* <ArgonBox pt={1} textAlign="left">
        <ArgonTypography variant="body1" textTransform="capitalize" color="white">
          {desc}
        </ArgonTypography>
      </ArgonBox> */}
      <ArgonBox py={3} textAlign="left">
        <ArgonBox component="img" src={image} borderRadius="sm" width="100%" />
      </ArgonBox>
    </ArgonBox>
  )
}

const ExampleCard = styled(ExampleTemplate)({
  backgroundColor: "transparent",
  "& a, & p": {
    color: "black"
  },
})

function ExampleSection({ className }) {
  return (
    <Container className={className}>
      <Grid container display="flex" alignItems="center">
        <Grid item sm={12} lg={6} display="flex" flexDirection="row-reverse">
          <ExampleCard
            title="P2PKH"
            href="https://docs.scrypt.io"
            image={codeImgP2PKH}
          />
        </Grid>
        <Grid item sm={12} lg={6} />
        <Grid item sm={12} lg={6} display="flex" flexDirection="row-reverse">
          <ArgonBox display="flex" justifyContent="space-around">
            <ArgonBox src={exIcon2} component="img" title="example icon 02" width="90%" sx={{ display: { xs: 'none', lg: 'flex' } }} />
          </ArgonBox>
        </Grid>
        <Grid item sm={12} lg={6} display="flex">
          <ExampleCard
            title="Turing Machines"
            href="https://medium.com/coinmonks/turing-machine-on-bitcoin-7f0ebe0d52b1"
            image={codeImgTuring}
          />
        </Grid>
        <Grid item sm={12} lg={6} display="flex" flexDirection="row-reverse">
          <ExampleCard
            title="ZK-Rollup"
            href="https://medium.com/xiaohuiliu/zk-rollups-on-bitcoin-ce35869b940d"
            image={codeImgZKP}
          />
        </Grid>
        <Grid item sm={12} lg={6} >
          <ArgonBox display="flex" justifyContent="space-around">
            <ArgonBox src={exIcon1} component="img" title="example icon 01" width="90%" sx={{ display: { xs: 'none', lg: 'flex' } }} />
          </ArgonBox>
        </Grid>

      </Grid>
    </Container>
  )
}

export default styled(ExampleSection)({
});