/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
// Authentication layout components
import PortalLayout from "pages/portal/components/Layout";

// Pricing page components
import Pricing from "./PricingSection";

import { styled } from "@mui/material/styles";


import Faq from "./components/Faq";
import { Container } from "@mui/material";
import Header from "./components/Header";

function PricingPage() {

    return (
        <PortalLayout >
            <Header />
            <Container >
                <Pricing />
                <Faq />
            </Container>
        </PortalLayout>
    );
}

export default styled(PricingPage)({
});
