/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";
import CheckIcon from "components/Icons/CheckIcon";

function DefaultEnterprisePricingCard({ title, specifications, action }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <ArgonBox key={label} display="flex" alignItems="center" p={1}>
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <CheckIcon />
      </ArgonBox>
      <ArgonTypography variant="body2" color="text">
        {label}
      </ArgonTypography>
    </ArgonBox>
  ));

  return (
    <Card sx={{ height: "100%", borderRadius: 1 }}>
      <ArgonBox pt={3} pb={2} px={2} textAlign="center">
        <ArgonTypography color="dark" textTransform="uppercase" fontWeight="medium">
          {title}
        </ArgonTypography>
        <ArgonBox my={1}>
          <ArgonTypography variant="h1" color="white">
            Custom
          </ArgonTypography>

        </ArgonBox>
        <>&nbsp;</>
      </ArgonBox >
      <ArgonBox pb={3} px={3}>
        <ArgonBox mt={1} mb={5}>
          {

            <ArgonBox mb={1}>
              <ArgonButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                fullWidth
              >
                {action.label}
              </ArgonButton>
            </ArgonBox>
          }
        </ArgonBox>
        {renderSpecifications}

      </ArgonBox>
      
    </Card>
  );
}

// Typechecking props for the DefaultEnterprisePricingCard
DefaultEnterprisePricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["external", "internal", "form"]).isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired

};

export default DefaultEnterprisePricingCard;
