/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";

// Billing page components
import BaseLayout from "pages/account/components/BaseLayout";
import Invoices from "pages/account/billing/components/Invoices";
import { useEffect, useState } from "react";
import { handleUnauthorized } from "utils";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";

// react-router components
import { Navigate, useNavigate } from "react-router-dom";
import Subscription from "./components/Subscription";
import MiniUsageCard from "./components/MiniUsageCard";

import { Helmet } from 'react-helmet';

function Billing() {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const [usage, setUsage] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {

      fetch(`/api/billing/periodusage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => handleUnauthorized(res, dispatch, navigate))
        .then(res => {
          const { statusCode, data } = res;
          if (statusCode === 0) {
            setUsage(data)
          }
        })
    }

  }, []);

  return (
    <BaseLayout stickyNavbar>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Subscription />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Grid item xs={12} >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} >
                        <MiniUsageCard title="APIs Daily Usage" total={usage.daily?.total} used={usage.daily?.used} />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <MiniUsageCard title="APIs Monthly Usage" total={usage.monthly?.total} used={usage.monthly?.used} />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} mt={3}>
                      <Grid item xs={12} sm={6} >
                        <MiniUsageCard title="Records Daily Usage" total={usage.daily?.recordTotal} used={usage.daily?.recordUsed} />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <MiniUsageCard title="Records Monthly Usage" total={usage.monthly?.recordTotal} used={usage.monthly?.recordUsed} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Invoices />
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default Billing;
