/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Props, useState } from 'react';
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";
import ArgonSnackbar from "base-components/ArgonSnackbar";
import Swal from "sweetalert2";
import FormField from "components/FormField";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

import { hashPassword, isValidPassword, isValidRepeatPassword } from "../utils";

// Image
import bgImage from "assets/images/banner-reset-password.png";

function ResetPasswordSubmit(prop) {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    const [errorSB, setErrorSB] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);

    const showError = (msg) => {
        setErrorMessage(msg);
        openErrorSB();
    }

    const renderErrorSB = (
        <ArgonSnackbar
            color="error"
            icon="error"
            title="Reset password failed"
            content={errorMessage}
            open={errorSB}
            onClose={closeErrorSB}
            close={closeErrorSB}
            light
        />
    );


    const submit = async (e) => {
        e.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        fetch('/api/auth/reset-password', {
            method: 'POST', // or 'PUT'
            body: JSON.stringify({
                newPasswordToken: token,
                newPassword: await hashPassword(password1)
            }), // data can be `string` or {object}!
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }).then(res => res.json())
            .then(async (response) => {
                if (response.statusCode === 0) {
                    showSuccess()
                } else {
                    showError(response.errorMsg)
                }
            })
            .catch(async (error) => {
                showError("Reset password failed.")
                console.error('Error:', error)
            })
    }


    const showSuccess = () => Swal.fire("Reset Password", "Resetting password succeeded.", "success")
        .then((result) => {
            window.location.href = '/sign-in';
        });

    return (
        <IllustrationLayout
            title="Reset Password"
            illustration={{
                image: bgImage,
                title: "Welcome to a new beginning",
                description:
                    "Forgetting is like a window that lets in the light of new experiences.",
            }}
        >
            <ArgonBox component="form" role="form">
                <ArgonBox mb={2}>
                    <FormField type="password" placeholder="New Password" size="large"
                        onChange={e => {
                            setPassword1(e.target.value)
                        }} label={password1 && !isValidPassword(password1) ? "Password requires a combination of at least 8 letters and numbers" : ""} />
                </ArgonBox>
                <ArgonBox mb={2}>
                    <FormField type="password" placeholder="Repeat New Password" size="large"
                        onChange={e => {
                            setPassword2(e.target.value)
                        }} label={(isValidPassword(password1) && password2 && !isValidRepeatPassword(password2, password1)) ? "Two password do not match." : ""}
                    />
                </ArgonBox>
                <ArgonBox mt={4} mb={1}>
                    <ArgonButton size="large" fullWidth onClick={submit} disabled={!isValidPassword(password1) || !isValidRepeatPassword(password2, password1)}>
                        Continue
                    </ArgonButton>
                    {renderErrorSB}
                </ArgonBox>
            </ArgonBox>
        </IllustrationLayout>
    );
}

export default ResetPasswordSubmit;
