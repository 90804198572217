// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Grid, Link } from "@mui/material";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

import taalLogo from "assets/images/logo_TAAL.png";
import nchainLogo from "assets/images/logo_Nchain.png";
import tokenovateLogo from "assets/images/logo_Tokenovate.png";
import exeterLogo from "assets/images/logo_ExeterUniversity.png";
import dotWalletLogo from "assets/images/logo_DotWallet.png";
import baLogo from "assets/images/logo_BitcoinAssociation.png";
import mvcLogo from "assets/images/logo_mvc.png";

function Partner({ title, href, img }) {
  return (
    <ArgonBox display="flex" alignItems="center" justifyContent="center"
      sx={{ border: '2px solid #3B3B3B', borderRadius: '4px', width: '300px', height: '120px' }} mx={3} my={4}>
      <Link href={href}>
        <ArgonBox src={img} component="img" title={title}  width="220px"
          sx={{  objectFit: 'contain' }} />
      </Link>
    </ArgonBox>
  )
}

function PartnerSection() {
  return (
    <Container>
      <ArgonBox width="100%" display="flex" alignItems="center" flexDirection="column" pb={3}>
        <ArgonBox display="flex">
          <ArgonTypography variant="h2" color="white" textTransform="uppercase">
            Partners
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="space-evenly" alignItems="center" sx={{ flexWrap: 'wrap' }}>
        <Partner title="TAAL" href="https://www.taal.com/" img={taalLogo} />
        <Partner title="NChain" href="https://nchain.com/" img={nchainLogo} />
        <Partner title="Bitcoin Association" href="https://association.bitcoinsv.com/" img={baLogo} />
        <Partner title="Tokenovate" href="https://www.tokenovate.com/" img={tokenovateLogo} />
        <Partner title="Exeter University" href="https://www.exeter.ac.uk/" img={exeterLogo} />
        <Partner title="MVC" href="https://www.microvisionchain.com/" img={mvcLogo} />
      </ArgonBox>
    </Container>
  )
}

export default PartnerSection;
