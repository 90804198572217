/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import PageLayout from "components/LayoutContainers/PageLayout";

// Argon Dashboard 2 PRO MUI page layout routes
import pageRoutes from "page.routes";

import DefaultNavbar from "pages/portal/components/DefaultNavbar";

import { styled } from "@mui/material/styles";

import Footer from "../Footer";

import {
  useArgonController,
  setDarkMode
} from "context";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';

function PortalLayout({ title, className, children }) {
  const [controller, dispatch] = useArgonController();

  useEffect(() => {
    // always use dark mode in portal pages.
    setDarkMode(dispatch, true);
  }, []);

  return (
    <ArgonBox className={className}>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <DefaultNavbar routes={pageRoutes} />
      {children}
      <Footer />
    </ArgonBox>
  );
}

// Setting default values for the props of IllustrationLayout
PortalLayout.defaultProps = {
};

// Typechecking props for the IllustrationLayout
PortalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default styled(PortalLayout)({
  backgroundColor: "black",
});
