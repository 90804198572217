// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import bannerImage from "assets/images/banner-landing.png";

import pxToRem from "assets/theme/functions/pxToRem";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";

function Banner({ className }) {
  return (
    <Container>
      <Grid className={className} container display="flex" justifyContent="space-between" alignItems="center">
        <Grid item xs={12} lg={7}>
          <ArgonTypography color="white" textTransform="capitalize">
            The&nbsp;
            <ArgonTypography component="span" color="yellow">
              FULL STACK
            </ArgonTypography>
            &nbsp;Web3 development platform for Bitcoin Compatible Blockchains
          </ArgonTypography>
        </Grid>
        <Grid item xs={12} lg={5} display="flex" flexDirection="row-reverse">
          <ArgonBox display="flex">
            <ArgonBox src={bannerImage} component="img" title="banner icon" width="100%" sx={{display: {xs: 'none', lg: 'flex'}}}/>
          </ArgonBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default styled(Banner)({
  "p, span": {
    fontSize: pxToRem(58),
    fontWeight: 'bold',
  },
});