/**
=========================================================
* Argon Dashboard 2 PRO MUI - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for ArgonButton
import ArgonButtonRoot from "base-components/ArgonButton/ArgonButtonRoot";

const ArgonButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, disabled, ...rest }, ref) => {

    if (disabled) {
      return <ArgonButtonRoot
        {...rest}
        ref={ref}
        color="dark"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        disabled
        ownerState={{ color, variant, size, circular, iconOnly, disabled }}
      >
        {children}
      </ArgonButtonRoot>
    }

    return <ArgonButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly, disabled }}
    >
      {children}
    </ArgonButtonRoot>
  }
);

// Setting default values for the props of ArgonButton
ArgonButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "primary",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the ArgonButton
ArgonButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "transparent",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "yellow"
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ArgonButton;
