/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";
import ArgonTypography from "base-components/ArgonTypography";
import { formatDate } from "utils";

function Invoice({ date, id, price, noGutter, url }) {
  return (
    <ArgonBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <ArgonBox pr={1}>
        <ArgonTypography variant="caption" fontWeight="regular" color="text">
          #{id}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pr={1}>
        <ArgonTypography display="block" variant="button" fontWeight="regular">
          {formatDate(date)}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox display="flex" alignItems="center">
        <ArgonTypography variant="button" fontWeight="regular" color="text">
          {price}
        </ArgonTypography>
        <ArgonBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{ cursor: "pointer" }}
        >
          {/* <ArgonButton component="a" href={url} size="small" variant="outlined">
            &nbsp;Details
          </ArgonButton> */}
          <ArgonBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            ml={3}
            sx={{ cursor: "pointer" }}
          >
            <Icon fontSize="small">picture_as_pdf</Icon>
            <ArgonTypography component="a" href={url} fontWeight="bold" variant="button">
              &nbsp;PDF
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
