/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

// Image
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-ill.jpg";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setUser
} from "context";

function ConfirmEmail() {
  const [, dispatch] = useArgonController();

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const urlParams = new URLSearchParams(window.location.search);
  const emailToken = urlParams.get('token');

  useEffect(() => {
    console.log(`token: \`${emailToken}\``)

    fetch('/api/auth/verify', {
      method: 'POST',
      body: JSON.stringify({
        emailToken
      }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(async (res) => {
      const response = await res.json();
      console.log(response)
      if (response.statusCode === 0) {
        // verify successfully
        console.log('verified')
        setTitle('Email Confirmation')
        setDescription(`You have successfully verified your email: ${response.data.email}`)
        // set user
        setUser(dispatch, response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      } else if (response.statusCode === 10000) {
        setTitle('Email Confirmation')
        setDescription('User not found')
      } else if (response.statusCode === 10008) {
        setTitle('Email Confirmation')
        setDescription('Invalid email verification token')
      }
    }).catch(error => {
      console.error('Error:', error)
      setTitle('Email Confirmation')
      setDescription('Confirm email failed.')
    })
  }, [])

  return (
    <IllustrationLayout
      title={title}
      description={description}
      illustration={{
        image: bgImage,
        title: "Your journey starts here",
        description:
          "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      }}
    >
      <ArgonBox component="form" role="form">

        <ArgonButton component={Link}
          to="/sign-in" size="large" fullWidth>
            Sign In
          </ArgonButton>
          

      </ArgonBox>
    </IllustrationLayout>
  );
}

export default ConfirmEmail;
