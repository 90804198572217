/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Props, useState } from 'react';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";
import Swal from "sweetalert2";
import FormField from "components/FormField";

import { isValidEmail } from "../utils";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/banner-reset-password.png";
import ArgonTypography from 'base-components/ArgonTypography';

function ResetPassword() {
    const [resetemail, setResetEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleReset = (e) => {
        e.preventDefault();
        fetch(`/api/auth/forgot-password/${resetemail}`).then(res => res.json())
            .then(async (response) => {
                if (response.statusCode === 0) {
                    showSuccess()
                } else {
                    setErrorMessage(response.errorMsg)
                }
            })
            .catch(async (error) => {
                console.error('Error:', error)
                setErrorMessage("Reset password failed.")
            })
    }

    const showSuccess = () => Swal.fire(
        "Reset Password",
        "<p>An email containing reset password instructions has been sent to your registered email address, follow the instructions in the email (valid within 24 hours) to reset your password.</p>",
        "success"
    )
        .then((result) => {
            window.location.href = '/sign-in';
        });


    return (
        <IllustrationLayout
            title="Reset Password"
            description="Reset password via email"
            illustration={{
                image: bgImage,
                title: "Welcome to a new beginning",
                description:
                    "Forgetting is like a window that lets in the light of new experiences.",
            }}
        >
            <ArgonBox component="form" role="form">
                <ArgonBox mb={2}>
                    <FormField type="email" placeholder="Email" size="large"
                        onChange={e => {
                            setResetEmail(e.target.value)
                        }} label={errorMessage} />
                </ArgonBox>
                <ArgonBox mt={4} mb={1}>
                    <ArgonButton size="large" fullWidth onClick={handleReset} disabled={!isValidEmail(resetemail)}>
                        Send
                    </ArgonButton>
                </ArgonBox>
            </ArgonBox>
        </IllustrationLayout>
    );
}

export default ResetPassword;
