// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

import { Container, Grid } from "@mui/material";

import bannerImage from "assets/images/banner-platform.png";
import num01 from "assets/images/number-01.png";
import num02 from "assets/images/number-02.png";
import num03 from "assets/images/number-03.png";
import num04 from "assets/images/number-04.png";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function FeatureTemplate({ className, title, desc, image }) {
  return (
    <ArgonBox className={className} display="flex" pt={2} pb={6} alignItems="center" flexWrap="wrap">
      <ArgonBox display="flex" sx={{ flex: "1 1 100%" }}>
        <ArgonBox display="flex" pr={2} component="img" src={image} width="54px" maxHeight="54px"/>
        <ArgonBox sx={{ backgroundColor: '#34200A' }} >
          <ArgonTypography variant="h4" color="yellow" px={2} py={1}>
            {title}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox display="flex" sx={{ flex: "1 1 100%" }}>
        <ArgonBox pr={2} width="54px" />
        <ArgonTypography variant="d8_5" pt={3}>
          {desc}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  )
}

const Feature = styled(FeatureTemplate)({
  "span": {
    color: "#f7f7f7",
  }
});

function PlatformSection() {
  const location = useLocation();
  const id = "development-platform-section";
  const scrollIntoView = () => {
    if (window.location.hash === `#${id}`) {
      document.querySelector(`#${id}`).scrollIntoView();
    }
  }

  useEffect(() => {
    setTimeout(scrollIntoView, 50);
  }, [location]);

  return (
    <Container id={id}>
      <ArgonBox width="100%" display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" >
        <ArgonBox display="flex" alignItems="center">
          <ArgonTypography variant="h2" color="white" textTransform="uppercase">
            The complete blockchain development platform
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center" py={2}>
          <ArgonTypography variant="subtitle1" color="white">
            The most potent suite of infra and tools for building and scaling your dApp effortlessly.
          </ArgonTypography>
        </ArgonBox>
        <Grid container display="flex" justifyContent="space-between" alignItems="center" py={6}>
          <Grid item sm={12} lg={7} display="flex" flexDirection="column">
            <Feature
              title="Seamless integration"
              desc="Exceptionally designed and documented SDK & APIs."
              image={num01}
            />
            <Feature
              title="Reliable blockchain access"
              desc="Without running a blockchain node yourself."
              image={num02}
            />
            <Feature
              title="Notify"
              desc="Real-time notifications for smart contract events."
              image={num03}
            />
            <Feature
              title="Wallet Support"
              desc="Supported by multiple wallets: Yours/Sensilet/DotWallet/TAAL."
              image={num04}
            />
          </Grid>
          <Grid item xs={12} lg={5} display="flex" flexDirection="row-reverse">
            <ArgonBox display="flex">
              <ArgonBox src={bannerImage} component="img" title="banner icon" width="100%" sx={{display: {xs: 'none', lg: 'flex'}}}/>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </Container>
  )
}

export default PlatformSection;