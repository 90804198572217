import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import { styled } from "@mui/material/styles";

function CopyRight() {
  return (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      color="text"
      px={1.5}
    >
      <ArgonTypography variant="d9">
        &copy; Copyright {new Date().getFullYear()}, sCrypt Inc
      </ArgonTypography>
    </ArgonBox>
  );
}

export default CopyRight;