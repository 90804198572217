/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DefaultPricingCard from "components/Cards/PricingCards/DefaultPricingCard";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React context
import { useArgonController, setUser } from "context";
import DefaultEnterprisePricingCard from "components/Cards/PricingCards/DefaultEnterprisePricingCard";

export class Grade {
  static STARTER = 'starter';
  static PRO = 'pro';
  static BUSINESS = 'business';
  static ENTERPRISE = 'enterprise';
}


function PricingCards({ prices, discounts }) {
  const [controller, dispatch] = useArgonController();
  const { user } = controller;

  const [userGrade, setUserGrade] = useState(Grade.STARTER)
  const [subscription, setSubscription] = useState(null)

  function getSubscription() {
    fetch('/api/billing/subscription').then(res => res.json()).then(response => {
      const { statusCode, data } = response;
      if (statusCode === 0) {
        setUserGrade(data.grade)
        setSubscription(data)
      }
    }).catch(error => {
      console.error('getSubscription error', error)
    });
  }

  useEffect(() => {
    if(user) {
      getSubscription()
    }

  }, [])
  const [starter, pro, business, enterprise] = prices;

  const products = [
    {
      grade: Grade.STARTER,
      price: starter,
      specifications: [
        { label: `20k Daily Requests`, includes: true },
        { label: "600k Monthly Requests", includes: true },
        { label: "10 Requests/Second", includes: true },
        { label: "Community Support", includes: true },
      ]
    },
    {
      grade: Grade.PRO,
      price: pro,
      specifications: [
        { label: "Unlimited Daily Requests", includes: true },
        { label: "2m Monthly Requests", includes: true },
        { label: "60 Requests/Second", includes: true },
        { label: "Direct Customer Support", includes: true },
      ]
    },
    {
      grade: Grade.BUSINESS,
      price: business,
      specifications: [
        { label: "Unlimited Daily Requests", includes: true },
        { label: "6m Monthly Requests", includes: true },
        { label: "120 Requests/Second", includes: true },
        { label: "Direct Customer Support", includes: true },
      ]
    },
    {
      grade: Grade.ENTERPRISE,
      price: enterprise,
      specifications: [
        { label: "Custom Limits", includes: true },
        { label: "Co-activation Marketing", includes: true },
        { label: "Usage Optimisation", includes: true },
        { label: "Prioritized Customer Support", includes: true },
      ]
    }
  ]

  return (
    <ArgonBox mt={10} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center" >

        {

          products.map(product => {
            if (product.grade === Grade.STARTER) {
              return (<Grid item xs={12} lg={3} key={product.grade} >
                <DefaultPricingCard
                  key={product.grade}
                  title={product.grade}
                  discounts={0}
                  price={{ currency: "$", value: product.price }}
                  specifications={product.specifications}
                  action={{
                    disabled: true,
                    type: "internal",
                    route: "#",
                    data: product.grade,
                    label: "Free",
                    color: "primary",
                  }}
                />
              </Grid>)
            } else if (product.grade === Grade.ENTERPRISE) {
              return (<Grid item xs={12} lg={3} key={product.grade} >
                <DefaultEnterprisePricingCard
                  key={product.grade}
                  title={product.grade}
                  discounts={0}
                  specifications={product.specifications}
                  action={{
                    disabled: false,
                    type: "internal",
                    route: "mailto:support@scrypt.io",
                    data: product.grade,
                    label: "Let's talk",
                    color: "primary",
                  }}
                />
              </Grid>)
            }

            if (!user || !subscription) {
              // no user login
              return (<Grid item xs={12} lg={3} key={product.grade} >
                <DefaultPricingCard
                  key={product.grade}
                  title={product.grade}
                  discounts={discounts}
                  price={{ currency: "$", value: product.price }}
                  specifications={product.specifications}
                  action={{
                    disabled: false,
                    type: "internal",
                    route: `/sign-in?redirect_uri=${encodeURIComponent("/pricing")}`,
                    data: product.grade,
                    label: "Subscribe",
                    color: "primary",
                  }}
                />
              </Grid>)
            } else if (subscription.status === "none") {
              // no user subscribe
              return (<Grid item xs={12} lg={3} key={product.grade} >
                <DefaultPricingCard
                  key={product.grade}
                  title={product.grade}
                  discounts={discounts}
                  price={{ currency: "$", value: product.price }}
                  specifications={product.specifications}
                  note={null}
                  action={{
                    disabled: false,
                    type: "form",
                    route: "/api/payment/create-checkout-session",
                    data: product.grade,
                    label: "Subscribe",
                    color: "primary",
                  }}
                />
              </Grid>)
            } else if (subscription.status === "active") {
              let label = userGrade === product.grade ? `Your Plan` : "Switch";

              if (subscription.cancel_at_period_end === true && userGrade === product.grade) {
                label = "Renew"
                return (<Grid item xs={12} lg={3} key={product.grade} >
                  <DefaultPricingCard
                    key={product.grade}
                    title={product.grade}
                    discounts={discounts}
                    price={{ currency: "$", value: product.price }}
                    specifications={product.specifications}
                    note={
                      {
                        label: "Plan ending on",
                        content: `${new Date(subscription.cancel_at * 1000).toLocaleDateString()}`
                      }
                    }
                    action={{
                      disabled: false,
                      type: "external",
                      route: `/api/billing/manage`,
                      label,
                      color: "primary",
                    }}
                  />
                </Grid>)
              } else {
                return (<Grid item xs={12} lg={3} key={product.grade} >
                  <DefaultPricingCard
                    key={product.grade}
                    title={product.grade}
                    discounts={discounts}
                    price={{ currency: "$", value: product.price }}
                    specifications={product.specifications}
                    action={{
                      disabled: userGrade === product.grade,
                      type: "internal",
                      route: `/payment/confirm?grade=${product.grade}`,
                      data: product.grade,
                      label,
                      color: "primary",
                    }}
                    note={null}
                  />
                </Grid>)
              }

            } else if (subscription.status === "past_due") {
              let label = userGrade === product.grade ? `Pay Invoice` : "Switch";

              return (<Grid item xs={12} lg={3} key={product.grade} >
                <DefaultPricingCard
                  key={product.grade}
                  title={product.grade}
                  discounts={discounts}
                  price={{ currency: "$", value: product.price }}
                  specifications={product.specifications}
                  note={
                    userGrade === product.grade ?
                      {
                        label: "Due Date",
                        content: `${new Date(subscription.due_at * 1000).toLocaleDateString()}`
                      } : null
                  }
                  action={{
                    disabled: userGrade === product.grade ? false : true,
                    type: "external",
                    route: subscription.pay_invoice,
                    label,
                    color: "primary",
                  }}
                />
              </Grid>)
            }
          })
        }
      </Grid>
    </ArgonBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.number).isRequired,
  discounts: PropTypes.number.isRequired,
};

export default PricingCards;
