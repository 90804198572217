

import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';
export const isValidEmail = (email) => email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)
export const isValidPassword = (password) => password && /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(password)
export const isValidRepeatPassword = (repeatPassword, password) => repeatPassword && password && repeatPassword === password

export const hashPassword = (str) => {
  // crypto.subtle only work on https, cannot work on http (localhost)
  return sha256(str).toString(Hex);
}
