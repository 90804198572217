/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
// Authentication layout components
import PortalLayout from "pages/portal/components/Layout";
// Images
import logoCT from "assets/images/logo-ct.png";
import logoCTDark from "assets/images/logo-ct-dark.png";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
// Argon Dashboard 2 PRO MUI base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

import { styled } from "@mui/material/styles";

import ArgonTypography from "base-components/ArgonTypography";
import pricingBG from "assets/images/pricing_page_bg.png";
import { Card, Container, Grid, Table, TableRow, TableBody } from "@mui/material";

import { Helmet } from 'react-helmet';
import ArgonButton from "base-components/ArgonButton";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function PayConfirm() {

    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const { borderWidth } = borders;
    const { light, dark } = colors;
    const borderBottom = `${borderWidth[1]} solid ${light.main}`;

    let [invoice, setInvoice] = useState(null);
    const grade = searchParams.get('grade');
    useEffect(() => {

        if (grade) {
            fetch(`/api/payment/update-plan-review?grade=${grade}`).then((res) => res.json()).then(res => {
                console.log('res', res)
                const { statusCode, data } = res;

                if (statusCode === 0) {
                    setInvoice(data)
                }
            }).catch(error => {
                console.error('Error:', error)
            });
        }
    }, [])

    return (<PortalLayout >

        <ArgonBox position="relative" >

            <ArgonBox
                position="absolute"
                component="img"
                src={pricingBG}
                sx={{ m: 0 }}
                alt="background"
                width="100%"
                bgColor="yellow">
            </ArgonBox>

            <ArgonBox pt={{ xs: 3, lg: 15 }}>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10} lg={10} >
                            <ArgonBox width="100%" display="flex" alignItems="center" flexDirection="column" pb={3}>
                                <ArgonBox display="flex">
                                    <ArgonTypography variant="h2" color="white">
                                        {
                                            `Are you sure you want to switch to ${searchParams.get('grade')} Plan?`
                                        }

                                    </ArgonTypography>
                                </ArgonBox>
                            </ArgonBox>

                        </Grid>

                        {
                            invoice ? (
                                <Grid item xs={12} sm={10} md={10}>
                                    <Card>
                                        {/* Invoice header */}
                                        <ArgonBox p={3}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item xs={12} md={4}>
                                                    <ArgonBox
                                                        component="img"
                                                        src={darkMode ? logoCT : logoCTDark}
                                                        width="25%"
                                                        p={1}
                                                        mb={1}
                                                    />
                                                    <ArgonTypography variant="h6" fontWeight="medium">
                                                        sCrypt
                                                    </ArgonTypography>

                                                </Grid>
                                                <Grid item xs={12} md={7} lg={3}>
                                                    <ArgonBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                                                        <ArgonBox mt={1}>
                                                            <ArgonTypography variant="h6" fontWeight="medium">
                                                                Billed to: {invoice.customer_email}
                                                            </ArgonTypography>
                                                        </ArgonBox>
                                                    </ArgonBox>
                                                </Grid>
                                            </Grid>
                                            <ArgonBox mt={{ xs: 5, md: 10 }}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item xs={12} md={4}>
                                                        <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
                                                            Invoice no
                                                        </ArgonTypography>
                                                        <ArgonTypography variant="h5" fontWeight="medium">
                                                            #draft
                                                        </ArgonTypography>
                                                    </Grid>
                                                    <Grid item xs={12} md={7} lg={5}>
                                                        <ArgonBox
                                                            width="100%"
                                                            display="flex"
                                                            flexDirection={{ xs: "column", md: "row" }}
                                                            alignItems={{ xs: "flex-start", md: "center" }}
                                                            textAlign={{ xs: "left", md: "right" }}
                                                            mt={{ xs: 3, md: 0 }}
                                                        >
                                                            <ArgonBox width="50%">
                                                                <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
                                                                    Invoice date:
                                                                </ArgonTypography>
                                                            </ArgonBox>
                                                            <ArgonBox width="50%">
                                                                <ArgonTypography variant="h6" fontWeight="medium">
                                                                    {new Date(invoice.created * 1000).toLocaleDateString()}
                                                                </ArgonTypography>
                                                            </ArgonBox>
                                                        </ArgonBox> {

                                                            invoice.due_date ? (<ArgonBox
                                                                width="100%"
                                                                display="flex"
                                                                flexDirection={{ xs: "column", md: "row" }}
                                                                alignItems={{ xs: "flex-start", md: "center" }}
                                                                textAlign={{ xs: "left", md: "right" }}
                                                            >
                                                                <ArgonBox width="50%">
                                                                    <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
                                                                        Due date:
                                                                    </ArgonTypography>
                                                                </ArgonBox>
                                                                <ArgonBox width="50%">
                                                                    <ArgonTypography variant="h6" fontWeight="medium">
                                                                        {new Date(invoice.due_date * 1000).toLocaleDateString()}
                                                                    </ArgonTypography>
                                                                </ArgonBox>
                                                            </ArgonBox>) : <ArgonBox />
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </ArgonBox>
                                        </ArgonBox>

                                        {/* Invoice table */}
                                        <ArgonBox p={3}>
                                            <ArgonBox width="100%" overflow="auto" borderRadius="xl">
                                                <Table sx={{ minWidth: "32rem" }}>
                                                    <ArgonBox bgColor="dark" component="thead">
                                                        <TableRow>
                                                            <ArgonBox
                                                                component="th"
                                                                width={{ xs: "45%", md: "50%" }}
                                                                py={1.5}
                                                                px={1}
                                                                textAlign="left"
                                                                borderBottom={borderBottom}
                                                            >
                                                                <ArgonTypography variant="h6" color="white" fontWeight="medium">
                                                                    Item
                                                                </ArgonTypography>
                                                            </ArgonBox>
                                                            <ArgonBox
                                                                component="th"
                                                                py={1.5}
                                                                pl={3}
                                                                pr={1}
                                                                textAlign="left"
                                                                borderBottom={borderBottom}
                                                            >
                                                                <ArgonTypography variant="h6" color="white" fontWeight="medium">
                                                                    Qty
                                                                </ArgonTypography>
                                                            </ArgonBox>
                                                            <ArgonBox
                                                                component="th"
                                                                py={1.5}
                                                                pl={3}
                                                                pr={1}
                                                                textAlign="left"
                                                                borderBottom={borderBottom}
                                                            >
                                                                <ArgonTypography variant="h6" color="white" fontWeight="medium">
                                                                    Amount
                                                                </ArgonTypography>
                                                            </ArgonBox>
                                                        </TableRow>
                                                    </ArgonBox>
                                                    <TableBody>

                                                        {
                                                            invoice.lines.data.map(item => (
                                                                <TableRow>
                                                                    <ArgonBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                                                                        <ArgonTypography variant="body2" color="text">
                                                                            {item.description}
                                                                        </ArgonTypography>
                                                                    </ArgonBox>
                                                                    <ArgonBox
                                                                        component="td"
                                                                        textAlign="left"
                                                                        py={1}
                                                                        pr={1}
                                                                        pl={3}
                                                                        borderBottom={borderBottom}
                                                                    >
                                                                        <ArgonTypography variant="body2" color="text">
                                                                            {`${item.quantity}`}
                                                                        </ArgonTypography>
                                                                    </ArgonBox>
                                                                    <ArgonBox
                                                                        component="td"
                                                                        textAlign="left"
                                                                        py={1}
                                                                        pr={1}
                                                                        pl={3}
                                                                        borderBottom={borderBottom}
                                                                    >
                                                                        <ArgonTypography variant="body2" color="text">
                                                                            {`\$${item.amount / 100}`}
                                                                        </ArgonTypography>
                                                                    </ArgonBox>
                                                                </TableRow>

                                                            ))
                                                        }

                                                        <TableRow>
                                                            <ArgonBox
                                                                component="td"
                                                                textAlign="left"
                                                                py={1}
                                                                pr={1}
                                                                pl={3}
                                                                borderBottom={borderBottom}
                                                            />
                                                            <ArgonBox
                                                                component="td"
                                                                textAlign="left"
                                                                py={1}
                                                                pr={1}
                                                                pl={3}
                                                                borderBottom={borderBottom}
                                                            >
                                                                <ArgonTypography variant="h5">Total</ArgonTypography>
                                                            </ArgonBox>
                                                            <ArgonBox
                                                                component="td"
                                                                textAlign="left"
                                                                py={1}
                                                                pr={1}
                                                                pl={3}
                                                                borderBottom={borderBottom}
                                                            >
                                                                <ArgonTypography variant="h5">{`\$${invoice.total / 100}`}</ArgonTypography>
                                                            </ArgonBox>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </ArgonBox>
                                        </ArgonBox>

                                        {/* Invoice footer */}
                                        <ArgonBox p={3} mt={7}>
                                            <Grid container>
                                                <Grid item xs={12} lg={5}>
                                                    <ArgonTypography variant="h5" fontWeight="medium">
                                                        Thank you!
                                                    </ArgonTypography>
                                                    <ArgonBox mt={1} mb={2} lineHeight={0}>
                                                        <ArgonTypography variant="button" fontWeight="regular" color="secondary">
                                                            If you encounter any issues related to the invoice you can contact us at:
                                                        </ArgonTypography>
                                                    </ArgonBox>
                                                    <ArgonTypography
                                                        component="span"
                                                        variant="h6"
                                                        fontWeight="medium"
                                                        color="secondary"
                                                    >
                                                        email:{" "}
                                                        <ArgonTypography component="span" variant="h6" fontWeight="medium">
                                                            support@scrypt.io
                                                        </ArgonTypography>
                                                    </ArgonTypography>
                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <ArgonBox
                                                        width="100%"
                                                        height={{ xs: "auto", md: "100%" }}
                                                        display="flex"
                                                        justifyContent={{ xs: "flex-start", md: "flex-end" }}
                                                        alignItems="flex-end"
                                                        mt={{ xs: 2, md: 0 }}
                                                    >

                                                        <ArgonButton component={Link} to="/pricing" color="dark" sx={{ mr: 2 }} >
                                                           Cancel
                                                        </ArgonButton>
                                                        <form action="/api/payment/update-plan" method="POST">
                                                            <input type="hidden" id="data" name="data" value={grade} />
                                                            <ArgonButton
                                                                color="info"
                                                                type="submit"
                                                                fullWidth
                                                     
                                                            >
                                                                Confirm
                                                            </ArgonButton>
                                                        </form>
                                                    </ArgonBox>
                                                </Grid>
                                            </Grid>
                                        </ArgonBox>
                                    </Card>
                                </Grid>
                            ) : (<Grid item xs={12} sm={10} md={10} />)
                        }

                    </Grid>

                </Container>
            </ArgonBox>
        </ArgonBox>
    </PortalLayout>);
}

export default styled(PayConfirm)({
});
