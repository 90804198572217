/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, useNavigate, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";

import BaseLayout from "pages/account/components/BaseLayout";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";
import React, { useEffect, useState } from "react";

import { Helmet } from 'react-helmet';

function WebhooksLayout({ title, desc, children }) {

  const [controller, dispatch] = useArgonController();
  const [network, setNetwork] = useState('mainnet');
  const options = [
    { value: 'mainnet', label: 'Mainnet' },
    { value: 'testnet', label: 'Testnet' }
  ]

  return (
    <BaseLayout stickyNavbar>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ArgonBox mt={4}>
        <ArgonBox mb={3} >
          <Grid container>
            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2} mb={3} >
                  <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {title}
                  </ArgonTypography>
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonTypography variant="d9" textTransform="capitalize" sx={{ pr: 1 }}>
                      Testnet
                    </ArgonTypography>
                    <Switch
                      checked={network === options[0].value}
                      onChange={() => {
                        setNetwork(network === options[0].value ? options[1].value : options[0].value)
                      }}
                    />
                    <ArgonTypography variant="d9" textTransform="capitalize" sx={{ pl: 1 }}>
                      Mainnet
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox mb={3} px={2} display="flex" alignItems="center" justifyContent="space-between">
                  <ArgonTypography variant="h6" fontWeight="light" >
                    {desc || 'Webhooks allow external services to be notified when certain events happen.'}
                  </ArgonTypography>
                  <ArgonBox pr={1}>
                    <ArgonButton component={Link} to={`/account/webhooks/create?network=${network}`}>Create</ArgonButton>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox pb={3} px={3}>
                  {
                    React.Children.map(children, (child) => {
                      // pass network prop to children
                      return React.cloneElement(child, { network })
                    })
                  }
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default WebhooksLayout;
