/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";

import BaseLayout from "pages/account/components/BaseLayout";


// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";
import { useEffect, useState } from "react";


import { Helmet } from 'react-helmet';
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";

import { handleUnauthorized } from "utils";

import { formatDate } from "utils";
import ArgonBadgeDot from "base-components/ArgonBadgeDot";

function Usage(props) {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const [usage, setUsage] = useState({
    usageToday: { totalCnt: 0 },
    usageMonth: { totalCnt: 0 },
  });

  const [chartData, setChartData] = useState(null);
  const [network, setNetwork] = useState('mainnet');


  const navigate = useNavigate();

  useEffect(() => {
    if (user && user._id && user.apiKeys) {

      const apiKey = (user.apiKeys || []).filter(apiKey => apiKey.startsWith(network))[0];

      fetch(`/api/billing/usage?apiKey=${apiKey}&network=${network}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => handleUnauthorized(res, dispatch, navigate))
        .then(res => {
          const { statusCode, data } = res;
          if (statusCode === 0) {
            setUsage(data);
          }
        })


      fetch(`/api/billing/usageLast30Days?apiKey=${apiKey}&network=${network}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => handleUnauthorized(res, dispatch, navigate))
        .then(res => {
          const { statusCode, data } = res;
          if (statusCode === 0) {
            setChartData({
              labels: data.map(d => formatDate(new Date(d.date))),
              datasets: [
                {
                  label: "API Requests",
                  color: "info",
                  data: data.map((d, i) => d.totalCnt)
                },
                {
                  label: "Records",
                  color: "primary",
                  data: data.map((d, i) => d.recordCnt)
                }
              ],
            });
          }
        })
    }
  }, [network]);

  const options = [
    { value: 'mainnet', label: 'Mainnet' },
    { value: 'testnet', label: 'Testnet' }
  ]

  return (
    <BaseLayout stickyNavbar>
      <Helmet>
        <title>Usage</title>
      </Helmet>
      <ArgonBox mt={4}>
        <ArgonBox mb={3} >
          <Grid container>
            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2} mb={3} >
                  <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    Usage
                  </ArgonTypography>
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonTypography variant="d9" textTransform="capitalize" sx={{ pr: 1 }}>
                      Testnet
                    </ArgonTypography>
                    <Switch
                      checked={network === options[0].value}
                      onChange={() => {
                        setNetwork(network === options[0].value ? options[1].value : options[0].value)
                      }}
                    />
                    <ArgonTypography variant="d9" textTransform="capitalize" sx={{ pl: 1 }}>
                      Mainnet
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox mb={3} px={2} >

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} >
                      <ArgonBox mb={3} >
                        <ArgonTypography variant="h6" fontWeight="light" >
                          Usage of all scrypt APIs, including total and successful calls:
                        </ArgonTypography>
                      </ArgonBox>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} >
                          <MiniStatisticsCard
                            title={{ text: "Today", fontWeight: "medium" }}
                            count={usage.usageToday.totalCnt || 0}
                            percentage={{ color: "success", text: `${usage.usageToday.successCnt || 0} successful` }}
                            icon={{ color: "primary", component: "data_usage" }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                          <MiniStatisticsCard
                            title={{ text: "This Month", fontWeight: "medium" }}
                            count={usage.usageMonth.totalCnt || 0}
                            percentage={{ color: "success", text: `${usage.usageMonth.successCnt || 0} successful` }}
                            icon={{ color: "primary", component: "data_usage" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <ArgonBox mb={3} >
                        <ArgonTypography variant="h6" fontWeight="light" >
                          Usage of all stream records:
                        </ArgonTypography>
                      </ArgonBox>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} >
                          <MiniStatisticsCard
                            title={{ text: "Today", fontWeight: "medium" }}
                            count={usage.usageToday.recordCnt || 0}
                            percentage={{ color: "success", text: `${usage.usageToday.successRecCnt || 0} successful` }}
                            icon={{ color: "primary", component: "data_usage" }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                          <MiniStatisticsCard
                            title={{ text: "This Month", fontWeight: "medium" }}
                            count={usage.usageMonth.recordCnt || 0}
                            percentage={{ color: "success", text: `${usage.usageMonth.successRecCnt || 0} successful` }}
                            icon={{ color: "primary", component: "data_usage" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ArgonBox>

                {chartData !== null && (
                  <ArgonBox mb={10} px={2} >
                    <DefaultLineChart
                      height={600}
                      description={
                        <ArgonBox display="flex">
                          <ArgonTypography variant="h6" fontWeight="light" >
                            The total number of requests per day for the last 30 days:
                          </ArgonTypography>
                          <ArgonBadgeDot color="info" size="sm" badgeContent="API Requests" />
                          <ArgonBadgeDot color="primary" size="sm" badgeContent="Records" />
                        </ArgonBox>
                      }
                      title="Recent 30 days"
                      chart={chartData}
                    />
                  </ArgonBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default Usage;
