/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonButton from "base-components/ArgonButton";
import ArgonTypography from "base-components/ArgonTypography";

import BaseLayout from "pages/account/components/BaseLayout";

import ApiKey from "./components/ApiKey";

// Argon Dashboard 2 PRO MUI context
import {
  setUser,
  useArgonController,
} from "context";
import { useEffect, useState } from "react";

import { handleUnauthorized } from "utils";
import { Helmet } from 'react-helmet';

function ApiKeys() {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;

  const navigate = useNavigate();
  const [apiKeys, setApiKeys] = useState([]);

  useEffect(() => {
    if (user && user._id) {
      fetch(`/api/users/${user._id}/api-keys`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => handleUnauthorized(res, dispatch, navigate))
        .then(({ data }) => {
          if (data.apiKeys) {
            const updatedUser = Object.assign({}, user, {
              apiKeys: data.apiKeys
            })
            localStorage.setItem('user', JSON.stringify(updatedUser))
            setUser(dispatch, updatedUser)
            setApiKeys(data.apiKeys);
          }
        })
    }
  }, []);

  const handleAddNewKey = () => {
    if (user && user._id) {
      fetch(`/api/users/${user._id}/api-keys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(res => handleUnauthorized(res, dispatch, navigate))
        .then(({ statusCode, data, errorMsg }) => {
          if (statusCode !== 0) {
            Swal.fire({
              title: "API Key Creation Failed",
              html: errorMsg,
              timer: 5000,
              timerProgressBar: true,
              showConfirmButton: false,
              toast: true,
              position: "bottom-end",
              icon: "error",
            })
            return;
          }
          if (data.apiKey) {
            setApiKeys([...apiKeys, data.apiKey]);
          }
        })
    }
  }

  const handleDeleteKey = (apiKey) => {
    setApiKeys(apiKeys.filter(key => key !== apiKey));
  }

  return (
    <BaseLayout stickyNavbar>
      <Helmet>
        <title>API Keys</title>
      </Helmet>
      <ArgonBox mt={4}>
        <ArgonBox mb={3}>
          <Grid container>
            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                  <ArgonTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    API Keys
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox pt={3} pb={2} px={2} display="flex" alignItems="center" justifyContent="space-between">
                  <ArgonTypography variant="h6" color="text" fontWeight="regular">
                    API Keys are used to authenticate your requests to the sCrypt services. Keep it secret and never share it with anyone.
                  </ArgonTypography>
                  <ArgonBox pr={1}>
                    <ArgonButton onClick={handleAddNewKey}>Create</ArgonButton>
                  </ArgonBox>
                </ArgonBox>

                {
                  (apiKeys || []).map((apiKey) => {
                    return (
                      <ArgonBox pb={3} px={2} key={`api_key_${apiKey}`}>
                        <Divider />
                        <ApiKey apiKey={apiKey} callback={handleDeleteKey} />
                      </ArgonBox>
                    )
                  })
                }
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </BaseLayout>
  );
}

export default ApiKeys;
