/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";
import CheckIcon from "components/Icons/CheckIcon";
import ArgonBadge from "base-components/ArgonBadge";

function DefaultPricingCard({ title, price, specifications, discounts, action, note }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <ArgonBox key={label} display="flex" alignItems="center" p={1}>
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <CheckIcon />
      </ArgonBox>
      <ArgonTypography variant="body2" color="text">
        {label}
      </ArgonTypography>
    </ArgonBox>
  ));

  return (
    <Card sx={{ height: "100%", borderRadius: 1 }}>
      <ArgonBox pt={3} pb={2} px={2} textAlign="center">
        <ArgonTypography color="dark" textTransform="uppercase" fontWeight="medium">
          {title}
        </ArgonTypography>
        <ArgonBox my={1}>
          <ArgonTypography variant="h1" color="white">
            <ArgonTypography display="inline" color="white" component="small" variant="h3">
              {price.currency}
            </ArgonTypography>
            {price.value * (discounts > 0 ? discounts : 1)}
            <ArgonTypography color="white" component="small" variant="h6"   >
              /month
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
        {
          discounts > 0 ?
            <>
              <ArgonTypography component="small" variant="h6">
                <del>${price.value}</del>, {(1 - discounts) * 100}% off&nbsp;
                <Icon fontSize="small" color="inherit">discount</Icon>
              </ArgonTypography>
            </>
            : <>&nbsp;</>
        }
      </ArgonBox>
      <ArgonBox pb={3} px={3}>
        <ArgonBox mt={1} mb={5}>
          {
            action.disabled ?
              (<ArgonButton
                color="dark"
                fullWidth
                disabled
              >
                {action.label}
              </ArgonButton>)
              :
              (
                <ArgonBox>
                  {action.type === "internal" ? (
                    <ArgonButton
                      component={Link}
                      to={action.route}
                      fullWidth
                    >
                      {action.label}
                    </ArgonButton>
                  ) : (
                    action.type === "form" ?
                      (
                        <form action={action.route} method="POST" onSubmit={action.onSubmit}>
                          <input type="hidden" id="data" name="data" value={action.data} />
                          <ArgonButton
                            color={action.color}
                            type="submit"
                            fullWidth
                          >
                            {action.label}
                          </ArgonButton>
                        </form>
                      ) :
                      (
                        <ArgonButton
                          component="a"
                          href={action.route}
                          target="_blank"
                          rel="noreferrer"
                          fullWidth
                        >
                          {action.label}
                        </ArgonButton>
                      ))}
                </ArgonBox>
              )
          }
        </ArgonBox>
        {renderSpecifications}

      </ArgonBox>
      {
        note ? (<ArgonBox pb={3} px={3}>
          <ArgonBox display="flex" justifyContent="space-between" mb={0.5}>
            <ArgonTypography variant="h6" mt={0.5} size="small" color="text">
              {note.label}
            </ArgonTypography>
            <ArgonBox ml={1}>
              <ArgonBadge
                variant="contained"
                color="secondary"
                size="md"
                badgeContent={note.content}
                container
              />
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>) : <></>
      }

    </Card >
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  discounts: PropTypes.number.isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.any.isRequired,
    type: PropTypes.oneOf(["external", "internal", "form"]).isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired

};

export default DefaultPricingCard;
