/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";

import BaseLayout from "pages/account/components/BaseLayout";
import WebhookBrief from "./components/WebhookBrief";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";
import { useEffect, useState } from "react";

import { Helmet } from 'react-helmet';
import { handleUnauthorized } from "utils";

import { formatDate } from "utils";
import WebhooksLayout from "./components/Layout";

function WebhookList({ network }) {
  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const [webhooks, setWebhooks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user._id && user.apiKeys) {
      const apiKey = (user.apiKeys || []).filter(apiKey => apiKey.startsWith(network))[0];

      fetch(`/api/webhooks?apiKey=${apiKey}&network=${network}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => handleUnauthorized(res, dispatch, navigate))
        .then(res => {
          if (res.statusCode === 0) {
            setWebhooks(res.data);
          }
        })
    }
  }, [network]);

  return (
    <ArgonBox minWidth="auto" sx={{ overflow: "scroll" }}>
      {
        webhooks.map(webhook => {
          return (
            <WebhookBrief
              key={webhook.webhookId}
              network={network}
              id={webhook.webhookId}
              contractId={`${webhook.contractId.txId}:${webhook.contractId.outputIndex}`}
              url={webhook.url}
              status={webhook.status}
              statusNote={webhook.statusNote}
              desc={webhook.desc}
            />
          )
        })
      }
    </ArgonBox>
  )
}

function Webhooks() {
  return (
    <WebhooksLayout title="Webhooks">
      <WebhookList />
    </WebhooksLayout>
  )
}

export default Webhooks;
