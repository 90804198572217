import { Helmet } from 'react-helmet';

function DefaultTitle() {
  return (
    <Helmet>
      <title>sCrypt | The Web3 Development Platform</title>
    </Helmet>
  )
}

export default DefaultTitle;