
import { useState } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";

import { hashPassword, isValidEmail, isValidPassword, isValidRepeatPassword } from "../utils";
import FormField from "components/FormField";
import Swal from "sweetalert2";

// Image
import bgImage from "assets/images/banner-signup.png";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setUser
} from "context";

export function VerifyEmail() {

    const [controller,] = useArgonController();
    const { user } = controller;
  
    const showSuccess = () => {
      Swal
        .fire("Verification Email", "Sent", "success")
        .then((result) => {
          window.location.href = '/sign-in';
        })
    }
  
    const showError = (errorMessage) => {
      Swal
        .fire("Verification Email", errorMessage, "error")
        .then((result) => {
        })
    }
  
    const handleClick = () => {

      fetch(`/api/auth/verify/${user.email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(async (res) => {
        const response = await res.json();
        console.log(response)
        if (response.statusCode === 0) {
          showSuccess()
        } else if (response.statusCode === 10003) {
          showError('Re-send too frequently')
        }
      }).catch(error => {
        console.error('Error:', error)
      })
    }
  
    return (
      <IllustrationLayout
        title={'Email Verification'}
        description="You will receive a verification email with instructions to verify your email address. If you don't find the email in your inbox, check the spam or junk folders. If you still can't find it, please click the resend button below."
        illustration={{
          image: bgImage,
          title: "Your journey starts here",
          description:
            "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
        }}
      >
        <ArgonBox component="form" role="form">
          <ArgonBox>
            to:&nbsp;&nbsp;{user?.email}
          </ArgonBox>
          <ArgonBox mt={4} mb={1}>
            <ArgonButton size="large" fullWidth onClick={handleClick}>
              Resend Email
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </IllustrationLayout>
    );
  }
  
  export default VerifyEmail;
