/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Navigate, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";


// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonInput from "base-components/ArgonInput";
import ArgonSelect from "base-components/ArgonSelect";
import ArgonDatePicker from "base-components/ArgonDatePicker";
import ArgonEditor from "base-components/ArgonEditor";
import ArgonDropzone from "base-components/ArgonDropzone";
import ArgonButton from "base-components/ArgonButton";


import BaseLayout from "pages/account/components/BaseLayout";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
} from "context";
import { useEffect, useState } from "react";

import { Helmet } from 'react-helmet';
import { handleUnauthorized, getDefaultApiKey } from "utils";

import Swal from "sweetalert2";

function ErrorMessage({ errMsg }) {
  return errMsg && (
    <ArgonBox my={1.5} lineHeight={0} display="inline-block">
      <ArgonTypography
        component="label"
        variant="button"
        fontWeight="regular"
        color="error"
      >
        {errMsg}
      </ArgonTypography>
    </ArgonBox>
  )
}

function CreateWebhook() {

  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const navigate = useNavigate();


  const [url, setUrl] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [network, setNetwork] = useState(urlParams.get('network') || 'mainnet');
  const [desc, setDesc] = useState(null);
  const [txId, setTxId] = useState(null);
  const [outputIndex, setOutputIndex] = useState(null);
  const [artifact, setArtifact] = useState(null);

  const [urlError, setUrlError] = useState(null);
  const [txIdError, setTxIdError] = useState(null);
  const [outputIndexError, setOutputIndexError] = useState(null);
  const [contractIdError, setContractIdError] = useState(null);
  const [artifactError, setArtifactError] = useState(null);

  const handleError = (errMsg) => {
    if (errMsg.includes('url')) {
      setUrlError(errMsg);
    }
    if (errMsg.includes('contractId.txId')) {
      setTxIdError(errMsg);
    }
    if (errMsg.includes('contractId.outputIndex')) {
      setOutputIndexError(errMsg);
    }
    if (errMsg.includes('Invalid contract outpoint')) {
      setContractIdError(errMsg);
    }
    if (errMsg.toLowerCase().includes('artifact')) {
      setArtifactError(errMsg);
    }
  }

  const submitCreateWebhook = () => {

    if (urlError || txIdError || outputIndexError || contractIdError || artifactError) {
      Swal.fire({
        title: "Please double-check the fields that contain errors before submitting the request.",
        icon: "error",
      });
      return;
    }

    fetch(`/api/webhooks/create?apiKey=${getDefaultApiKey(user, network)}&network=${network}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        contractId: { txId, outputIndex: parseInt(outputIndex) },
        url, desc,
        artifact,
      })
    })
      .then(res => handleUnauthorized(res, dispatch, navigate))
      .then(res => {
        if (res.statusCode === 0) {
          Swal
            .fire({
              title: "Webhook Created",
              icon: "success",
            })
            .then(() => {
              window.location.href = '/account/webhooks';
            })
        } else {
          [res.errorMsg].flat().map(handleError);
        }
      })
  }

  return (
    <BaseLayout stickyNavbar>
      <Helmet>
        <title>Create Webhook</title>
      </Helmet>
      <ArgonBox mt={4} mb={3} >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h6" fontWeight="medium">
                  Create new webhook
                </ArgonTypography>
                <Divider />

                <ArgonBox mt={3} mb={5}>
                  <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                      URL
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonInput placeholder="Enter a webhook URL" type="url" error={urlError !== null}
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setUrlError(null);
                    }} />
                  <ErrorMessage errMsg={urlError} />
                </ArgonBox>

                <ArgonBox mt={3} mb={5}>
                  <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                      Network
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox ml={0.5}>
                    <ArgonTypography component="label" variant="caption">
                      Testnet&nbsp;
                    </ArgonTypography>
                    <Switch
                      checked={network === 'mainnet'}
                      onChange={(e) => setNetwork(e.target.checked ? 'mainnet' : 'testnet')}
                    />
                    <ArgonTypography component="label" variant="caption">
                      &nbsp;Mainnet
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>

                <ArgonBox mt={3} mb={5}>
                  <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                      Description
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonInput placeholder="Enter a description for the webhook" onChange={(e) => setDesc(e.target.value)} />
                </ArgonBox>

                <ArgonBox mt={3} mb={5}>
                  <ArgonBox mb={1} ml={0.5} lineHeight={0}>
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                      Contract Id
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox pl={0.5} pb={1.5}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                      The webhook will be notified for events from this contract. Furthermore, the contract should be deployed and called via our services.
                    </ArgonTypography>
                  </ArgonBox>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ArgonInput
                        placeholder="Enter a valid contract deploy tx id"
                        onChange={e => { setTxId(e.target.value); setTxIdError(null); setContractIdError(null) }}
                        error={txIdError !== null}
                      />
                      <ErrorMessage errMsg={txIdError} />
                    </Grid>
                    <Grid item xs={6}>
                      <ArgonInput
                        type="number"
                        placeholder="Enter the output index of the deploy tx"
                        onChange={e => { setOutputIndex(e.target.value); setOutputIndexError(null); setContractIdError(null) }}
                        error={outputIndexError !== null}
                      />
                      <ErrorMessage errMsg={outputIndexError} />
                    </Grid>
                  </Grid>
                  <ErrorMessage errMsg={contractIdError} />
                </ArgonBox>

                <ArgonBox mt={3} mb={5}>
                  <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography component="label" variant="caption" fontWeight="bold">
                      Artifact File Content
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox pl={0.5} pb={1.5}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                      The artifact file content is used to provide neccessary information to decode the contract call data.
                      It usually can be found in the file like `artfacts/contract_name.json` in your sCrypt project.
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox pl={0.5} pb={1.5}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      color="text"
                    >
                      It's only required at the first time of creating a webhook for a certain contract specified by the tx id and the output index.
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonInput
                    placeholder="Paste the artifact file content here."
                    multiline
                    rows={20}
                    onChange={e => {
                      const artString = e.target.value;
                      try {
                        const artifact = JSON.parse(artString);
                        if (artString.trim().startsWith("{")) {
                          setArtifact(artifact);
                          setArtifactError(null);
                        } else {
                          throw new Error('Invalid artifact file format');
                        }
                      } catch (error) {
                        setArtifactError('Invalid artifact file format, should be a valid JSON');
                      }
                    }}
                    error={artifactError !== null}
                  />
                  <ErrorMessage errMsg={artifactError} />
                </ArgonBox>
                <ArgonBox display="flex" justifyContent="flex-end" mt={3}>
                  <ArgonBox mr={1}>
                    <ArgonButton color="light" component="a" href="/account/webhooks">Cancel</ArgonButton>
                  </ArgonBox>
                  <ArgonButton variant="gradient" color="info" onClick={submitCreateWebhook}>
                    Create Webhook
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    </BaseLayout>
  );

}

export default CreateWebhook;
