import { useState, useEffect, Fragment } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "base-components/ArgonBox";
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";


// Material Dashboard 2 PRO React context
import { useArgonController, setUser } from "context";

import typography from "assets/theme/base/typography";

function GoDashboardAndLogout({ itemMr, itemMy }) {
  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  return (
    <>
      <ArgonBox
        mr={itemMr}
        my={itemMy}
        textAlign="center"
        component={Link}
        to="/account/api-keys"
        color="yellow"
        sx={{ fontSize: typography.subtitle2 }}
      >
        My Dashboard
      </ArgonBox>

      <ArgonButton
        onClick={() => {
          fetch('/api/auth/logout', {
            method: 'POST',
          }).then(async (res) => {
            setUser(dispatch, null);
            localStorage.removeItem('user');
          }).catch(
            error => console.error('Error:', error)
          );
        }}
        color="error"
        size="small"
        sx={{ fontSize: typography.subtitle2, bgColor: "yellow", color: "black" }}
      >
        Logout
      </ArgonButton>
    </>
  )
}

function SignInAndSignUp({ itemMr, itemMy }) {
  return (
    <>
      <ArgonBox
        textAlign="center"
        component={Link}
        to="/sign-in"
        mr={itemMr}
        my={itemMy}
        color="yellow"
        sx={{ fontSize: typography.subtitle2 }}
      >
        Sign In
      </ArgonBox>

      <ArgonButton
        component={Link}
        to="/sign-up"
        size="small"
        sx={{ fontSize: typography.subtitle2 }}
        onClick={() => {
          if(gtag) {
            gtag('event', 'sign_up', {
              'event_category': 'engagement',
              'event_label': 'landing_page'
            });
          }
          return true;
        }}
      >
        Sign Up
      </ArgonButton>
    </>
  )
}

function UserNav({ itemMr, itemMy }) {
  const [controller, dispatch] = useArgonController();
  const { user } = controller;
  const [isLogin, setIsLogin] = useState(false);
  function getProfile() {
    fetch('/api/auth/profile').then(res => res.json()).then(response => {
      if (response.statusCode === 0) {
        setIsLogin(true)
      } else {
        setIsLogin(false)
        setUser(dispatch, null);
        localStorage.removeItem('user');
      }
    }).catch(error => {
    });
  }

  useEffect(() => {
    getProfile()
  }, [])

  return isLogin && user
    ? (<GoDashboardAndLogout itemMr={itemMr} itemMy={itemMy} />)
    : (<SignInAndSignUp itemMr={itemMr} itemMy={itemMy} />)
}

UserNav.defaultProps = {
  itemMr: 0,
  itemMy: 0,
}

UserNav.propTypes = {
  itemMr: PropTypes.number,
  itemMy: PropTypes.number,
}

export default UserNav;