// @mui material components
import KeyIcon from '@mui/icons-material/Key';

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "base-components/ArgonTypography";
import ArgonButton from "base-components/ArgonButton";
import { useState } from "react";
import { Grid } from "@mui/material";
import { useArgonController } from "context";
import { handleUnauthorized } from "utils";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ApiKey(props) {
  const { apiKey, callback } = props;

  // testnet_
  // mainnet_
  const prefix = apiKey.substring(0, 8)
  const mask = `${prefix}${'*'.repeat(apiKey.length - prefix.length)}`

  const [displayedKey, setDisplayedKey] = useState(mask)
  const [keyButtonText, setKeyButtonText] = useState('Show')
  const [copyButtonText, setCopyButtonText] = useState('Copy')

  const [controller, dispatch] = useArgonController();
  const { user } = controller;

  const navigate = useNavigate();

  const handleDeleteKey = () => {
    Swal.fire({
      title: 'Confirm Deletion',
      text: "Are you sure you would like to delete this API key? This action cannot be undone.",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.value) {
        if (user && user._id) {
          fetch(`/api/users/${user._id}/api-keys`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: user._id, apiKey })
          })
            .then(res => handleUnauthorized(res, dispatch, navigate))
            .then(({ statusCode, data, errorMsg }) => {
              let title, icon
              if (statusCode === 0 && data) {
                title = 'API Key Delete Successfully'
                icon = 'success'
                callback(apiKey)
              } else {
                title = 'API Key Delete Failed'
                icon = 'error'
              }
              Swal.fire({
                title,
                html: errorMsg,
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false,
                toast: true,
                position: "bottom-end",
                icon,
              })
            })
        }
      }
    });
  }

  return (
    <Grid container>
      <Grid item xs={12} md={8} alignItems={"center"} py={2} display={{ xs: "block", lg: "inline-flex" }}>
        <ArgonTypography component="span">
          <KeyIcon />
        </ArgonTypography>
        <ArgonTypography pl={1} variant="body2">
          {displayedKey}
        </ArgonTypography>
      </Grid>
      <Grid item xs={12} md={4} display="flex" justifyContent="flex-end" alignItems="center">
        <ArgonButton
          variant="outlined"
          size="small"
          sx={{ marginRight: "1rem" }}
          onClick={() => {
            const fromHideToShow = keyButtonText === 'Show'
            setDisplayedKey(fromHideToShow ? apiKey : mask)
            setKeyButtonText(fromHideToShow ? 'Hide' : 'Show')
          }}
        >
          {keyButtonText}
        </ArgonButton>
        <ArgonButton
          variant="outlined"
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(apiKey)
            setCopyButtonText('Copied!')
            setTimeout(() => {
              setCopyButtonText('Copy')
            }, 1000);
          }}
        >
          {copyButtonText}
        </ArgonButton>
        <ArgonButton
          variant="outlined"
          size="small"
          sx={{ marginLeft: "1rem" }}
          onClick={handleDeleteKey}
        >
          Delete
        </ArgonButton>
      </Grid>
    </Grid>

  );
}

export default ApiKey;
